import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-fullscreen-modal-card',
    templateUrl: './fullscreen-modal-card.component.html',
    styleUrls: ['./fullscreen-modal-card.component.scss'],
    standalone: false
})
export class FullscreenModalCardComponent {
}
