import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeatureFlagsService } from '../../../services/feature-flags.service';
import { PartnerContextService } from '../../../services/partner-context.service';

@Injectable({
	providedIn: 'root',
})
export class PartnerContextGuard implements CanActivate {
	constructor(private partnerContext: PartnerContextService, private featureFlags: FeatureFlagsService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return this.partnerContext.updateContextForCurrentRoute(state.url).pipe(map(() => true));
	}
}
