import { Component, Input, OnInit } from '@angular/core';
import { expandRows, fadeInOut, rotate180deg, smoothOpenClose } from 'app/animations/smooth.animations';
import { TransactionDto } from 'app/api';

@Component({
    selector: 'app-transaction-item',
    templateUrl: './transaction-item.component.html',
    styleUrls: ['./transaction-item.component.scss'],
    animations: [smoothOpenClose, rotate180deg, expandRows, fadeInOut],
    standalone: false
})
export class TransactionItemComponent implements OnInit {
	@Input() transaction: TransactionDto | any;
	@Input() open = false;

	constructor() {}

	ngOnInit(): void {}

	isPayment() {
		return this.transaction.transaction_type === 'payment';
	}

	isPlannedPayment() {
		return this.transaction.payment_reason === 'Planned';
	}

	isManualPayment() {
		return this.transaction.payment_reason === 'Manual';
	}

	isExtraAmortizationPayment() {
		return this.transaction.payment_reason === 'ExtraAmortizationOnly';
	}

	isMonitorImportedPayment() {
		return this.transaction.feed_type === 'monitor-imported';
	}

	isWithdrawal() {
		return this.transaction.transaction_type === 'withdrawal';
	}

	abs(value) {
		return Math.abs(value);
	}

	isNextPayment() {
		return this.transaction.estimated_date ? true : false;
	}
}
