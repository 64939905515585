import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { expandRows, fadeInOut, smoothOpenClose } from 'app/animations/smooth.animations';
import { OrganizationEventDto } from 'app/api';
import { EventsService } from 'app/services';
import { OrganizationEventExtended } from '../../../helpers/events.helper';
import { LoansOverviewService } from '../../../services/loans-overview.service';

@Component({
    selector: 'app-events-card',
    templateUrl: './events-card.component.html',
    styleUrls: ['./events-card.component.scss'],
    animations: [smoothOpenClose, fadeInOut, expandRows],
    standalone: false
})
export class EventsCardComponent implements OnInit, OnChanges {
	@Input() events: OrganizationEventDto[];
	@Input() hideLoadMoreButton = false;
	@Input() loading: boolean;

	@Input() canLoadMore: boolean;
	@Input() expandOnLoad = true;

	@Output() clickedMore = new EventEmitter<void>();
	@Output() load = new EventEmitter<void>();

	firstEventExpanded = false;
	mappedEvents: OrganizationEventExtended[] = [];

	constructor(
		private loansOverviewService: LoansOverviewService,
		private eventsService: EventsService,
		private translateService: TranslateService
	) {}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges) {
		this.translateService.onLangChange.subscribe(_ => {
			this.mappedEvents = this.eventsService.formatEventsForDisplay(this.events);
		});

		if (changes.events) {
			this.mappedEvents = this.eventsService.formatEventsForDisplay(this.events);
		}
	}

	visibilityChangeHandler(visibility: 'visible' | 'hidden') {
		if (
			this.expandOnLoad &&
			!!this.mappedEvents &&
			this.mappedEvents.length > 0 &&
			!this.firstEventExpanded &&
			visibility === 'visible'
		) {
			this.mappedEvents[0].expanded = true;
			this.firstEventExpanded = true;
		}
	}
}
