import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { smoothOpenClose } from 'app/animations/smooth.animations';
import {
	CountryISOCode,
	GroupedUltimateBeneficialOwnerDto,
	KycHttpService,
	PepType,
	SetKycDataRequest,
	TrapetsHttpService,
} from 'app/api';
import { OrganizationService } from 'app/services';
import { WhiteLabelClientSettingsService } from 'app/services/white-label-client-settings.service';
import {
	atLeastOneCountryValidator,
	atLeastOnePEPValidator,
	atLeastOneUBOValidator,
	pepTypeValidator,
} from 'app/shared/validators';
import { BehaviorSubject, Subject, from } from 'rxjs';
import {
	debounceTime,
	distinctUntilChanged,
	filter,
	map,
	switchMap,
	take,
	takeUntil,
	tap,
	toArray,
} from 'rxjs/operators';
import { CountryNameService } from '../../../services/country-name.service';

@Component({
	selector: 'app-kyc',
	templateUrl: './kyc.component.html',
	styleUrls: ['./kyc.component.scss'],
	animations: [smoothOpenClose],
	standalone: false,
})
export class KycComponent implements OnInit, OnDestroy {
	@Input() kyc: SetKycDataRequest = null;
	@Input() shouldRedirectToNewLoanStep = false;
	@Input() showSaveButton = true;
	@Input() showUbosAndPep = true;
	@Output() saveKyc: EventEmitter<{ organizationId: string; data: SetKycDataRequest }> = new EventEmitter<any>();
	ubosDataSource$ = new BehaviorSubject<GroupedUltimateBeneficialOwnerDto[]>([]);
	highRiskCountriesDataSource$ = new BehaviorSubject<{ code: CountryISOCode; name: string }[]>([]);
	kycForm: FormGroup;
	addUBOModalVisible = false;
	protected readonly destroy$ = new Subject();
	ubosLoading = false;
	loading = false;
	countryCode: string;
	organizationId: string;

	@Input() PEPTypeOptions: { value: PepType; text: string }[] = [
		{ value: PepType.Pep, text: 'kyc.pep-type.pep' },
		{ value: PepType.PepRelative, text: 'kyc.pep-type.pep-relative' },
		{ value: PepType.PepCloseAssociate, text: 'kyc.pep-type.pep-close-associate' },
	];

	constructor(
		protected formBuilder: FormBuilder,
		protected route: ActivatedRoute,
		protected trapetsService: TrapetsHttpService,
		protected kycHttpService: KycHttpService,
		protected organizationService: OrganizationService,
		protected countryNameService: CountryNameService,
		protected clientSettingsService: WhiteLabelClientSettingsService
	) {}

	ngOnInit(): void {
		this.organizationId = this.organizationService.getOrganizationId();
		this.countryCode = this.clientSettingsService.getSettings()?.countryCode ?? CountryISOCode.SE;
		this.setHighRiskCountriesDataSource();
		this.setUboDataSource();
		this.setFormValues();
	}

	setFormValues() {
		this.initializeForm();

		this.ubosDataSource$.pipe(takeUntil(this.destroy$)).subscribe(ubos => {
			this.setFormUBOs(ubos);
			this.watchIsAnyOwnerPEPValueChanges();
		});

		this.highRiskCountriesDataSource$
			.pipe(
				filter(countries => countries.length > 0),
				take(1),
				takeUntil(this.destroy$)
			)
			.subscribe(countries => {
				this.setFormHighRiskCountries(countries);
				this.watchBusinessOutsideEUValueChanges();
				this.watchHighRiskCountriesValueChanges();
				this.watchNoCountriesValueChanges();
			});
	}

	initializeForm(): void {
		const ubos = this.kyc ? this.kyc.groupedUltimateBeneficialOwners : [];
		this.kycForm = this.formBuilder.group({
			ubos: [ubos],
			isAnyOwnerPEP: [this.kyc && ubos?.some(o => o.is_pep), { validators: [Validators.required] }],
			businessOutsideEU: [this.kyc ? this.kyc.doesOperateOutsideEu : '', { validators: [Validators.required] }],
			isPEP: [true, { validators: [Validators.required] }],
			highRiskCountries: [this.kyc ? this.kyc.highRiskCountries : []],
			noCountries: [this.kyc && this.kyc.doesOperateOutsideEu && this.kyc.highRiskCountries.length === 0],
		});
	}

	setUboDataSource() {
		if (!this.kyc?.groupedUltimateBeneficialOwners) {
			this.setUboDataSourceFromTrapets();
		} else {
			this.setUboDataSourceFromKyc();
		}
	}

	setUboDataSourceFromTrapets() {
		this.ubosLoading = true;
		this.kycHttpService.getUltimateBeneficialOwners(this.organizationId).subscribe(data => {
			this.ubosLoading = false;
			//only set data from trapets if there is no data from kyc
			if (this.ubosDataSource$.value.length === 0) {
				this.ubosDataSource$.next(data || []);
			}
		});
	}

	setUboDataSourceFromKyc() {
		this.ubosLoading = true;
		this.ubosDataSource$.next(this.kyc?.groupedUltimateBeneficialOwners || []);
		this.ubosLoading = false;
	}

	setHighRiskCountriesDataSource() {
		this.trapetsService
			.getHighRiskCountries()
			.pipe(
				switchMap(countries =>
					from(countries).pipe(
						map(country => {
							return { code: country, name: this.countryNameService.getNameByCode(country) };
						}),
						toArray()
					)
				),
				map(countries =>
					countries.sort((a, b) => {
						const nameA = a.name.charAt(0);
						const nameB = b.name.charAt(0);
						if (nameA < nameB) {
							return -1;
						}
						if (nameA > nameB) {
							return 1;
						}
						return 0;
					})
				)
			)
			.subscribe(countries => this.highRiskCountriesDataSource$.next(countries));
	}

	watchIsAnyOwnerPEPValueChanges() {
		this.isAnyOwnerPEP.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
			if (!value) {
				this.ubos.controls.forEach(ubo => {
					ubo.patchValue({ is_pep: false });
				});
			} else if (value && !this.showUbosAndPep) {
				this.ubos.controls.forEach(ubo => {
					ubo.patchValue({ is_pep: true });
				});
			}
			this.ubos.updateValueAndValidity();
		});
	}

	watchBusinessOutsideEUValueChanges() {
		this.businessOutsideEU.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
			if (!value) {
				this.highRiskCountries.controls.forEach(country => {
					country.patchValue({ checked: false });
				});
			}
			this.highRiskCountries.updateValueAndValidity();
		});
	}

	watchHighRiskCountriesValueChanges() {
		this.highRiskCountries.valueChanges
			.pipe(
				debounceTime(200),
				filter(_ => this.noCountries.value),
				map(highRiskCountries => highRiskCountries.filter(country => country.checked)),
				filter(checkedCountries => checkedCountries.length > 0),
				distinctUntilChanged(),
				takeUntil(this.destroy$)
			)
			.subscribe(_ => {
				this.noCountries.setValue(false);
			});
	}

	watchNoCountriesValueChanges() {
		this.noCountries.valueChanges
			.pipe(
				filter(checked => checked),
				takeUntil(this.destroy$)
			)
			.subscribe(_ => {
				this.highRiskCountries.controls.forEach(country => {
					country.patchValue({ checked: false });
				});
				this.highRiskCountries.updateValueAndValidity();
			});
	}

	setFormUBOs(ubos: GroupedUltimateBeneficialOwnerDto[]) {
		this.kycForm.setControl(
			'ubos',
			this.formBuilder.array(
				ubos?.map(ubo =>
					this.formBuilder.group(
						{
							first_name: [ubo.first_name ? ubo.first_name : ''],
							last_name: [ubo.last_name ? ubo.last_name : ''],
							ssn: [ubo.ssn],
							is_pep: [!!ubo.is_pep],
							hashed_ssn: ubo.hashed_ssn,
							taxpayer_identification_number: [ubo.taxpayer_identification_number],
							hashed_taxpayer_identification_number: ubo.hashed_taxpayer_identification_number,
							pep_type: [this.PEPTypeOptions.find(pepOption => pepOption?.value == ubo.pep_type) || ''],
						},
						{ validators: [pepTypeValidator()] }
					)
				) || [],
				{ validators: [atLeastOneUBOValidator(), atLeastOnePEPValidator(() => this.isAnyOwnerPEP.value)] }
			)
		);
	}

	setFormHighRiskCountries(countries: { code: CountryISOCode; name: string }[]) {
		this.kycForm.setControl(
			'highRiskCountries',
			this.formBuilder.array(
				countries.map(c =>
					this.formBuilder.group({
						name: [c.name],
						code: [c.code],
						checked: this.isChecked(c.code),
					})
				),
				{
					validators: [
						atLeastOneCountryValidator(
							() => this.businessOutsideEU.value,
							() => this.noCountries.value
						),
					],
				}
			)
		);
	}

	isChecked(code: CountryISOCode) {
		let checked = false;
		if (this.kyc && this.kyc.highRiskCountries?.includes(code)) {
			checked = true;
		}
		return checked;
	}

	deleteUBO(i) {
		this.ubosDataSource$
			.pipe(
				take(1),
				map(ubos => {
					ubos.splice(i, 1);
					return ubos;
				})
			)
			.subscribe(ubos => this.ubosDataSource$.next(ubos));
	}

	addUBO(ubo: GroupedUltimateBeneficialOwnerDto) {
		this.ubosDataSource$
			.pipe(
				take(1),
				map(ubos => {
					ubos.push(ubo);
					return ubos;
				}),
				tap(_ => (this.addUBOModalVisible = false))
			)
			.subscribe(ubos => this.ubosDataSource$.next(ubos));
	}

	get isAnyOwnerPEP(): AbstractControl {
		return this.kycForm.get('isAnyOwnerPEP');
	}

	get businessOutsideEU(): AbstractControl {
		return this.kycForm.get('businessOutsideEU');
	}

	get ubos(): UntypedFormArray {
		return this.kycForm.get('ubos') as UntypedFormArray;
	}

	get highRiskCountries(): UntypedFormArray {
		return this.kycForm.get('highRiskCountries') as UntypedFormArray;
	}

	get noCountries(): AbstractControl {
		return this.kycForm.get('noCountries');
	}

	save() {
		this.loading = true;
		this.kycForm.markAllAsTouched();
		if (this.kycForm.valid) {
			const data = this.mapFormToKycDataRequest();
			this.saveKyc.emit({
				organizationId: this.organizationId,
				data: data,
			});
			this.loading = false;
		} else {
			this.loading = false;
		}
	}

	mapFormToKycDataRequest(): SetKycDataRequest {
		return {
			highRiskCountries: this.highRiskCountries.value.filter(c => c.checked).map(c => c.code),
			doesOperateOutsideEu: this.businessOutsideEU.value,
			groupedUltimateBeneficialOwners: this.ubos?.value?.map(u => {
				return {
					first_name: u.first_name,
					last_name: u.last_name,
					ssn: u.ssn,
					taxpayer_identification_number: u.taxpayer_identification_number,
					hashed_ssn: u.hashed_ssn,
					hashed_taxpayer_identification_number: u.hashed_taxpayer_identification_number,
					is_pep: u.is_pep,
					pep_type: u.pep_type ? u.pep_type.value : PepType.NotPep,
					country: this.countryCode,
				};
			}),
		};
	}

	isFormValid(): boolean {
		this.kycForm.markAllAsTouched();
		return this.kycForm.valid;
	}

	ngOnDestroy(): void {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}
}
