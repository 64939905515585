import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MoreSniOptions } from 'app/models/moreSniOptions';
import { SniOption } from 'app/models/sniOption';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-sni-selector',
    templateUrl: './sni-selector.component.html',
    styleUrl: './sni-selector.component.scss',
    standalone: false
})
export class SniSelectorComponent implements OnInit, OnDestroy {
	@Input() defaultSNIOptions: SniOption[];
	@Input() moreSNIOptions: MoreSniOptions[];
	@Input() intialValue: SniOption;
	@Output() selectedSNIValue = new EventEmitter<SniOption>();

	moreOptionsVisible = false;

	selectSNIForm: FormGroup;
	SNIOptions: SniOption[] = [];
	protected readonly destroy$ = new Subject();

	constructor(private formBuilder: FormBuilder) {}

	ngOnInit() {
		this.SNIOptions = this.defaultSNIOptions;
		this.initializeForm();
		this.watchSelectedSNIChanges();
	}

	initializeForm() {
		this.selectSNIForm = this.formBuilder.group({
			selectedSNI: [this.intialValue, { validators: [Validators.required] }],
		});
	}

	watchSelectedSNIChanges() {
		this.selectedSNI.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
			this.selectedSNIValue.emit(value);
		});
	}

	get selectedSNI(): AbstractControl {
		return this.selectSNIForm.get('selectedSNI');
	}

	addNewSniOption(option: SniOption) {
		const optionExists = this.SNIOptions.some(existingOption => existingOption.code === option.code);
		if (!optionExists) {
			this.SNIOptions.push(option);
			this.selectedSNI.setValue(option);
		}
	}

	openMoreSNIOptions() {
		this.moreOptionsVisible = true;
	}

	closeMoreSNIOptions() {
		this.moreOptionsVisible = false;
	}

	ngOnDestroy() {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}
}
