import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { CreditProductHttpService } from 'app/api';
import { PaymentIntervalType } from 'app/api/model/paymentIntervalType';
import { WithdrawalRequestTermsDto } from 'app/api/model/withdrawalRequestTermsDto';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { OrganizationService } from './organization.service';

@Injectable()
export class CreditTermsService {
	private creditTermsSource$ = new BehaviorSubject<WithdrawalRequestTermsDto | HttpErrorResponse>(null);

	constructor(
		private creditProductService: CreditProductHttpService,
		private organizationService: OrganizationService
	) {}

	private hasTerms(): boolean {
		return !!this.creditTermsSource$.value;
	}

	private getTerms(): Observable<WithdrawalRequestTermsDto | HttpErrorResponse> {
		return this.creditTermsSource$.asObservable();
	}

	getPaymentIntervals(): Observable<PaymentIntervalType[]> {
		if (!this.hasTerms()) {
			this.runTerms().pipe(take(1)).subscribe();
		}

		return this.getTerms().pipe(
			map(terms => {
				if (!terms || terms instanceof HttpErrorResponse) {
					return [];
				}
				return terms.available_payment_intervals || [];
			})
		);
	}

	runTerms(creditIntent = false): Observable<WithdrawalRequestTermsDto | HttpErrorResponse> {
		if (!this.hasTerms()) {
			if (creditIntent) this.runTermsWihCreditIntent();
			else this.runTermsWithoutCreditIntent();
		}
		return this.creditTermsSource$.asObservable().pipe(filter(terms => !!terms));
	}

	runTermsWihCreditIntent() {
		const organizationId = this.organizationService.getOrganizationId();
		const creditId = this.organizationService.getCreditId();

		this.creditProductService.runTermsForCreditWithCreditIntent(organizationId, creditId).subscribe(
			terms => {
				this.creditTermsSource$.next(terms);
			},
			error => {
				this.handleTermsError(error, organizationId, creditId, true);
				this.creditTermsSource$.next(error);
			}
		);
	}

	runTermsWithoutCreditIntent() {
		const organizationId = this.organizationService.getOrganizationId();
		const creditId = this.organizationService.getCreditId();

		this.creditProductService.runTermsForCredit(organizationId, creditId).subscribe(
			terms => {
				this.creditTermsSource$.next(terms);
			},
			error => {
				this.handleTermsError(error, organizationId, creditId, false);
				this.creditTermsSource$.next(error);
			}
		);
	}

	private handleTermsError(
		error: HttpErrorResponse,
		organizationId: string,
		creditId: string,
		withCreditIntent: boolean
	) {
		// Skip Sentry logging for expected 400 errors - rejects
		if (error.status === 400) {
			return;
		}

		Sentry.captureException(new Error('[CreditTerms] Failed to fetch terms'), {
			contexts: {
				credit: {
					organizationId,
					creditId,
					withCreditIntent,
				},
				error: {
					status: error.status,
					message: error.message,
					details: error.error,
				},
			},
			tags: {
				location: `CreditTermsService.runTerms${withCreditIntent ? 'WihCreditIntent' : 'WithoutCreditIntent'}`,
				errorType: 'credit_terms_failure',
			},
			level: 'error',
		});
	}

	clearTerms(): void {
		this.creditTermsSource$.next(null);
	}
}
