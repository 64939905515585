import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CountryISOCode, GroupedUltimateBeneficialOwnerDto, PepType } from 'app/api';
import { WhiteLabelClientSettingsService } from 'app/services/white-label-client-settings.service';
import { ssnDanishValidator, ssnValidator, tinGermanValidator } from 'app/shared/validators';

@Component({
	selector: 'add-ubo-modal',
	templateUrl: './add-ubo-modal.component.html',
	styleUrls: ['./add-ubo-modal.component.scss'],
	standalone: false,
})
export class AddUboModalComponent implements OnInit {
	@Input() visible = false;
	@Output() close = new EventEmitter<void>();
	@Output() add = new EventEmitter<GroupedUltimateBeneficialOwnerDto>();
	uboForm: FormGroup;
	partner: string | null = null;
	countryCode: string;

	constructor(
		protected formBuilder: FormBuilder,
		protected route: ActivatedRoute,
		protected clientSettingsService: WhiteLabelClientSettingsService
	) {}

	get firstName(): AbstractControl {
		return this.uboForm.get('firstName');
	}

	get lastName(): AbstractControl {
		return this.uboForm.get('lastName');
	}

	get ssn(): AbstractControl {
		return this.uboForm.get('ssn');
	}

	get taxpayerIdentificationNumber(): AbstractControl {
		return this.uboForm.get('taxpayerIdentificationNumber');
	}

	get usesTaxpayerIdentificationNumber() {
		return this.countryCode === CountryISOCode.DE;
	}

	ngOnInit() {
		this.route.parent.paramMap.subscribe(params => {
			this.partner = params.get('partner');
			if (this.partner) {
				this.countryCode = this.clientSettingsService.getSettings().countryCode;
			} else {
				this.countryCode = CountryISOCode.SE;
			}
			this.initializeForm();
		});
	}

	get validator() {
		if (this.countryCode === CountryISOCode.DK) {
			return ssnDanishValidator;
		} else {
			return ssnValidator;
		}
	}

	initializeForm() {
		this.uboForm = this.formBuilder.group({
			firstName: ['', { validators: [Validators.required] }],
			lastName: ['', { validators: [Validators.required] }],
			...(this.usesTaxpayerIdentificationNumber
				? { taxpayerIdentificationNumber: ['', { validators: tinGermanValidator, updateOn: 'blur' }] }
				: { ssn: ['', { validators: this.validator, updateOn: 'blur' }] }),
		});
	}

	addUBO() {
		this.uboForm.markAllAsTouched();
		if (this.uboForm.valid) {
			const ubo: GroupedUltimateBeneficialOwnerDto = {
				first_name: this.firstName.value,
				last_name: this.lastName.value,
				is_pep: false,
				pep_type: PepType.NotPep,
				ubo_types: ['beneficialOwner'],
			};
			if (this.usesTaxpayerIdentificationNumber) {
				ubo.taxpayer_identification_number = this.taxpayerIdentificationNumber.value.replace(/\D/g, '');
			} else {
				ubo.ssn = this.ssn.value.replace(/\D/g, '');
			}
			this.add.emit(ubo);
		}
	}
}
