import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
@Component({
    selector: 'app-svg-icon',
    templateUrl: './svg-icon.component.html',
    styleUrls: ['./svg-icon.component.scss'],
    standalone: false
})
export class SvgIconComponent implements OnChanges, OnInit {
	@Input() iconId: string;
	@Input() iconName: string;
	@Input() width?: string = '24';
	@Input() height?: string = '24';
	@Input() viewBox?;
	@Input() iconPath?: string = 'assets/shared/icons/';
	@Input() rotate?: string;

	ngOnInit() {
		//set viewbox based on height and width if it's not provided
		if (!this.viewBox) this.viewBox = `0 0 ${this.width} ${this.height}`;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.viewBox || changes.width || changes.height) this.viewBox = `0 0 ${this.width} ${this.height}`;
	}
}
