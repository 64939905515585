import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-ubo-list-item',
    templateUrl: './ubo-list-item.component.html',
    styleUrls: ['./ubo-list-item.component.scss'],
    standalone: false
})
export class UboListItemComponent {
	@Input() title: string;
	@Input() description: string;
	@Output() onRemoveClick = new EventEmitter<number>();
}
