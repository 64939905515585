import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LocalStorageService } from '@services';

@Component({
    selector: 'app-infomercial-video',
    templateUrl: './infomercial-video.component.html',
    styleUrls: ['./infomercial-video.component.scss'],
    standalone: false
})
export class InfomercialVideoComponent implements OnInit {
	videoUrl: SafeResourceUrl;
	private readonly VIDEO_URLS = {
		sv: 'https://player.vimeo.com/video/1037448134',
		en: 'https://player.vimeo.com/video/1038190540',
	};

	constructor(private localStorageService: LocalStorageService, private sanitizer: DomSanitizer) {}

	ngOnInit() {
		const chosenLocale = this.localStorageService.get('chosenLocale') || 'sv';
		const url = this.VIDEO_URLS[chosenLocale] || this.VIDEO_URLS.sv;
		this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}
}
