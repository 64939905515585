import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ScrapingCallbacksHttpService } from 'app/api';
import { CallbackStatus } from 'app/api/model/callbackStatus';
import { Observable, Subject, of, throwError, timer } from 'rxjs';
import {
	catchError,
	exhaustMap,
	filter,
	mergeMap,
	publishBehavior,
	refCount,
	retryWhen,
	take,
	takeUntil,
} from 'rxjs/operators';

@Component({
    selector: 'app-scraping-callback-modal',
    templateUrl: './scraping-callback-modal.component.html',
    styleUrls: ['./scraping-callback-modal.component.scss'],
    standalone: false
})
export class ScrapingCallbackModalComponent implements OnInit, OnDestroy {
	@Input() visible: boolean;
	@Input() sessionId: string;
	@Output() close = new EventEmitter<void>();
	@Output() completed = new EventEmitter<void>();
	callbackStatus$: Observable<CallbackStatus>;
	private readonly destroy$ = new Subject<string>();

	constructor(private scrapingService: ScrapingCallbacksHttpService) {}

	ngOnInit(): void {
		this.callbackStatus$ = timer(0, 1000).pipe(
			exhaustMap(_ => this.scrapingService.scrapingCallbackStatus(this.sessionId)),
			//retry for 5 minutes
			retryWhen(errors => errors.pipe(mergeMap((error, i) => (i > 150 ? throwError(error) : timer(2000))))),
			catchError(error => of(CallbackStatus.Failed)),
			publishBehavior(CallbackStatus.Processing),
			refCount()
		);

		this.callbackStatus$
			.pipe(
				filter(status => status == CallbackStatus.Completed),
				take(1),
				takeUntil(this.destroy$)
			)
			.subscribe(() => this.completed.emit());
	}

	ngOnDestroy() {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}
}
