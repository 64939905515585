import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeInOut } from 'app/animations/smooth.animations';

@Component({
    selector: 'app-modal-card',
    templateUrl: './modal-card.component.html',
    styleUrls: ['./modal-card.component.scss'],
    animations: [fadeInOut],
    standalone: false
})
export class ModalCardComponent implements OnInit {
	@Input() size = 'md';
	@Input() visible = false;
	@Input() closable = true;
	@Input() fullsizeMobile = false;
	@Output() close = new EventEmitter<void>();

	constructor() {}

	ngOnInit(): void {}
}
