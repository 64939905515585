import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ssnValidator } from 'app/shared/validators';

@Component({
    selector: 'dev-ssn-modal',
    templateUrl: './dev-ssn-modal.component.html',
    styleUrls: ['./dev-ssn-modal.component.scss'],
    standalone: false
})
export class DevSsnModalComponent implements OnInit {
	@Input() visible = false;
	@Output() continue = new EventEmitter<void>();
	@Output() close = new EventEmitter<void>();
	ssnForm: FormGroup;

	constructor(protected formBuilder: FormBuilder) {}

	get ssn(): AbstractControl {
		return this.ssnForm.get('ssn');
	}

	ngOnInit() {
		this.ssnForm = this.formBuilder.group({
			ssn: ['199504236249', { validators: ssnValidator, updateOn: 'blur' }],
		});
	}

	continueClicked() {
		this.ssnForm.markAllAsTouched();
		if (this.ssnForm.valid) {
			this.continue.emit(this.ssn.value);
		}
	}
}
