import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
	Event,
	NavigationEnd,
	NavigationStart,
	PRIMARY_OUTLET,
	Router,
	RouterEvent,
	UrlSegmentGroup,
	UrlTree,
} from '@angular/router';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { environment } from 'environments/environment';
import { VERSION } from 'environments/version';
import { Observable, Subject } from 'rxjs';
import { shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { CreditDto, CreditsHttpService, LoggingHttpService } from './api';
import { PAGES_WITH_AVAILABLE_CREDIT, TOP_UP_PAGE } from './my-froda/my-froda.constants';
import { OrganizationService, SentryService, UserService } from './services';
import { CspService } from './services/csp.service';
import { AuthService } from './shared/auth';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit, OnDestroy {
	private credit$: Observable<CreditDto>;
	private organizationId: string;
	protected readonly destroy$ = new Subject();

	constructor(
		private router: Router,
		private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
		private organizationService: OrganizationService,
		private userService: UserService,
		private loggingService: LoggingHttpService,
		private creditService: CreditsHttpService,
		private titleService: Title,
		private authService: AuthService,
		private sentryService: SentryService,
		private cspService: CspService
	) {
		this.trackRouterEvents();
		this.angulartics2GoogleTagManager.startTracking();
		this.writeComments();
		this.cspService.init();
	}

	ngOnInit() {
		this.setSentryUserAndOrganization();
	}

	writeComments(): void {
		const versionComment = document.createComment(`Version: ${VERSION}`);
		const environmentComment = document.createComment(`Environment: ${environment.environmentName}`);

		document.appendChild(versionComment);
		document.appendChild(environmentComment);
	}

	trackRouterEvents() {
		this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event: Event | RouterEvent) => {
			if (event instanceof NavigationStart) {
				const isWhiteLabelRoute = event?.url.startsWith('/p/');
				// Fallback to 'Mitt Froda' and froda favicon for routes other than white label routes.
				if (!isWhiteLabelRoute) {
					this.setDefaultTitleAndIcon();
				}
			}
			if (event instanceof NavigationEnd) {
				window.scrollTo(0, 0);
				const isMyFrodaRoute = event?.url.startsWith('/v2');
				if (event?.url && isMyFrodaRoute && this.authService.isAuthenticated().isAuthenticated) {
					this.trackPageLoadedEvent(event);
				}
			}
		});
	}

	setDefaultTitleAndIcon() {
		this.titleService.setTitle('Mitt Froda');
		const favIcon: HTMLLinkElement = document.querySelector('#favicon');
		favIcon.href = '/assets/shared/icons/favicon.ico';
		const touchIcon: HTMLLinkElement = document.querySelector('#touch-icon');
		touchIcon.href = '/assets/froda/icons/froda-touch-icon.png';
	}

	trackPageLoadedEvent(event: RouterEvent) {
		this.organizationId = this.organizationId ?? this.organizationService.getOrganizationId();
		this.credit$ = this.credit$ ?? this.creditService.getPrimaryCredit(this.organizationId).pipe(shareReplay(1));
		const user = this.userService.getUser();

		const tree: UrlTree = this.router.parseUrl(event.url);
		const group: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
		const segments = group.segments;
		const myFrodaRouteName = segments[1]?.path || 'home';

		this.credit$
			.pipe(
				switchMap(credit => {
					const logEvent = {
						organization_id: this.organizationId,
						user_id: user.id,
						event_type: event.url === TOP_UP_PAGE ? 'topup.loaded' : `${myFrodaRouteName}.loaded`,
						properties: PAGES_WITH_AVAILABLE_CREDIT.includes(event?.url)
							? [
									{
										key: 'credit-available',
										value: credit.size_remaining.amount.toString(),
									},
							  ]
							: [],
					};
					return this.loggingService.logInteractionEvent(logEvent);
				})
			)
			.subscribe();
	}

	setSentryUserAndOrganization() {
		const user = this.userService.getUser();
		const organizationId = this.organizationService.getOrganizationId();
		if (user?.id) {
			this.sentryService.setUserId(user.id);
		}
		if (organizationId) {
			this.sentryService.setOrganizationId(organizationId);
		}
	}

	ngOnDestroy() {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}
}
