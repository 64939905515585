import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CreditDto, FixedLoanPriceResponseDto, FlexLoanPriceResponseDto, PaymentIntervalType } from 'app/api';
import { LoanService } from 'app/services/loan.service';
import { WhiteLabelClientSettingsService } from 'app/services/white-label-client-settings.service';
import { BehaviorSubject, Observable, Subject, iif } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'loan-summary',
    templateUrl: './loan-summary.component.html',
    styleUrls: ['./loan-summary.component.scss'],
    standalone: false
})
export class LoanSummaryComponent implements OnInit, OnDestroy {
	@Input() showFixedInterest = false;
	@Input() className = 'text-black';
	@Input() minLoanAmount = 5000;
	@Input() credit: CreditDto;
	amount$: Observable<number>;
	amount: number;
	duration: number;
	duration$: Observable<number>;
	offer$: Observable<FixedLoanPriceResponseDto | FlexLoanPriceResponseDto>;
	offerStale$ = new BehaviorSubject<boolean>(false);
	isFlexLoan$: Observable<boolean>;
	monthlyInterestRate$: Observable<number>;
	protected readonly destroy$ = new Subject();
	currency: string;
	isPartnerIntegration: boolean;
	paymentInterval$: Observable<PaymentIntervalType>;
	paysDaily$: Observable<boolean>;
	paysWeekly$: Observable<boolean>;
	paysMonthly$: Observable<boolean>;

	constructor(private loanService: LoanService, private clientSettingsService: WhiteLabelClientSettingsService) {}

	ngOnInit(): void {
		this.isPartnerIntegration = !!this.clientSettingsService.getSettings();
		this.currency = this.isPartnerIntegration ? this.clientSettingsService.getSettings().currency : 'SEK';

		this.amount$ = this.loanService.amount$.pipe(distinctUntilChanged(), takeUntil(this.destroy$));
		this.duration$ = this.loanService.duration$.pipe(distinctUntilChanged(), takeUntil(this.destroy$));

		this.isFlexLoan$ = this.loanService.flexState$.pipe(distinctUntilChanged(), takeUntil(this.destroy$));

		const fixedOffer$ = this.loanService.offer$.pipe(distinctUntilChanged(), takeUntil(this.destroy$));
		const flexOffer$ = this.loanService.flexOffer$.pipe(distinctUntilChanged(), takeUntil(this.destroy$));

		this.offer$ = this.isFlexLoan$.pipe(mergeMap(isFlexLoan => iif(() => isFlexLoan, flexOffer$, fixedOffer$)));

		this.monthlyInterestRate$ = flexOffer$.pipe(
			filter(offer => !!offer),
			map(offer => (offer as any).price_point.interestChangeFactor / 12)
		);

		this.checkPaymentInterval();

		this.amount$.pipe(distinctUntilChanged()).subscribe(amount => {
			this.amount = amount;
			if (this.isValidLoanRequest) {
				this.offerStale$.next(true);
			} else {
				this.offerStale$.next(false);
			}
		});

		this.duration$.pipe(distinctUntilChanged()).subscribe(duration => {
			this.duration = duration;
			if (this.isValidLoanRequest) {
				this.offerStale$.next(true);
			} else {
				this.offerStale$.next(false);
			}
		});

		this.offer$.pipe(distinctUntilChanged()).subscribe(offer => {
			this.offerStale$.next(false);
		});
	}

	get hasPreviousLoan(): boolean {
		return this.credit && this.credit?.credit_used.amount > 0;
	}

	get isValidLoanRequest(): boolean {
		return (
			this.duration >= this.credit?.min_duration &&
			this.duration <= this.credit?.max_duration &&
			this.amount >= this.minLoanAmount &&
			this.amount <= this.credit?.size_available.amount
		);
	}

	checkPaymentInterval() {
		this.paymentInterval$ = this.loanService.paymentInterval$.pipe(
			map(paymentInterval => paymentInterval.value),
			takeUntil(this.destroy$)
		);
		this.paysDaily$ = this.paymentInterval$.pipe(
			map(interval => interval === PaymentIntervalType.Daily || interval === PaymentIntervalType.Bankday)
		);
		this.paysWeekly$ = this.paymentInterval$.pipe(
			map(interval => interval === PaymentIntervalType.WeeklySimple || interval === PaymentIntervalType.Weekly)
		);
		this.paysMonthly$ = this.paymentInterval$.pipe(map(interval => interval === PaymentIntervalType.Monthly));
	}

	ngOnDestroy() {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}
}
