import { ProductionBootstrapper } from '../app/bootstrapping/production.bootstrapper';

export const environment = {
	production: true,
	enableTracing: false,
	bootstrapper: ProductionBootstrapper,
	environmentName: 'Prod',
	sentryDsn: 'https://32dc518847ae44e894275c5368e0cef9@o4505187202760704.ingest.sentry.io/4505187207086080',
	moduleFederation: {
		selfServiceHost: 'https://home.froda.se/helix/selfservice',
	},
};
