import { Component, OnInit } from '@angular/core';
import { fadeInOut } from 'app/animations/smooth.animations';

@Component({
    selector: 'app-three-dots',
    templateUrl: './three-dots.component.html',
    styleUrls: ['./three-dots.component.scss'],
    animations: [fadeInOut],
    standalone: false
})
export class ThreeDotsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
