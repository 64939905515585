import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StatusCardType } from 'app/models/statusCardType';

@Component({
    selector: 'my-home-status-card',
    templateUrl: './my-home-status-card.component.html',
    styleUrls: ['./my-home-status-card.component.scss'],
    standalone: false
})
export class MyHomeStatusCardComponent implements OnInit {
	@Input() type: StatusCardType = StatusCardType.Alert;
	@Input() closable = false;
	@Input() collapsable = false;
	@Output() close = new EventEmitter<void>();
	StatusCardType = StatusCardType;
	collapsed = true;

	constructor() {}

	ngOnInit(): void {}
}
