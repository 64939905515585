import { Pipe, PipeTransform } from '@angular/core';
import { PaymentIntervalType } from 'app/api';
import { LocalStorageService } from 'app/services';

@Pipe({
    name: 'interval',
    standalone: false
})
export class IntervalPipe implements PipeTransform {
	constructor(private localStorage: LocalStorageService) {}

	transform(value: string, paymentInterval: PaymentIntervalType): string {
		const chosenLang = this.localStorage.get('chosenLocale');
		let intervalSuffix = '';
		switch (paymentInterval) {
			case PaymentIntervalType.Monthly:
				intervalSuffix = chosenLang === 'sv' ? '/mån' : chosenLang === 'en' ? '/month' : '';
				break;
			case PaymentIntervalType.Weekly:
				intervalSuffix = chosenLang === 'sv' ? '/vecka' : chosenLang === 'en' ? '/week' : '';
				break;
			case PaymentIntervalType.Bankday:
			case PaymentIntervalType.Daily:
				intervalSuffix = chosenLang === 'sv' ? '/vardag' : chosenLang === 'en' ? '/day' : '';
			default:
				break;
		}
		return value + intervalSuffix;
	}
}
