import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { rotate270deg, smoothOpenClose } from 'app/animations/smooth.animations';
import { AnalyticsService } from 'app/services/analytics.service';

@Component({
    selector: 'app-home-faqs',
    templateUrl: './home-faqs.component.html',
    styleUrls: ['./home-faqs.component.scss'],
    animations: [smoothOpenClose, rotate270deg],
    standalone: false
})
export class HomeFaqsComponent implements OnInit {
	@Input() showTitle = true;
	@Output() openFaqs = new EventEmitter<void>();
	@Input() keys: string[];
	opened: boolean[];

	constructor(private analyticsService: AnalyticsService) {}

	ngOnInit(): void {
		this.opened = Array(this.keys.length).fill(false);
	}

	toggle(key, i) {
		this.opened[i] = !this.opened[i];
		if (this.opened[i]) this.analyticsService.registerEvent('home.faq.click', key);
	}
}
