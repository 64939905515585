import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WhiteLabelClientSettingsService } from 'app/services/white-label-client-settings.service';
import { DefaultSpinnerComponent } from '../../../tailwind-theme-config/assets/spinner/default-spinner/default-spinner.component';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    standalone: false
})
export class LoaderComponent implements OnInit {
	spinnerComponent: any;

	constructor(private route: ActivatedRoute, private clientSettingsService: WhiteLabelClientSettingsService) {}

	ngOnInit() {
		this.route.parent.paramMap.subscribe(params => {
			const partner = params.get('partner');
			if (partner) {
				this.spinnerComponent = this.clientSettingsService.getSettings().spinner;
			} else {
				this.spinnerComponent = DefaultSpinnerComponent;
			}
		});
	}
}
