import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'submit-loan-modal',
    templateUrl: './submit-loan-modal.component.html',
    styleUrls: ['./submit-loan-modal.component.scss'],
    standalone: false
})
export class SubmitLoanModalComponent implements OnInit {

  @Input() visible  = false;

  constructor() { }

  ngOnInit(): void {
  }

}
