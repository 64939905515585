import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-default-spinner',
    templateUrl: './default-spinner.component.html',
    styleUrls: ['./default-spinner.component.scss'],
    standalone: false
})
export class DefaultSpinnerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
