import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlagsService } from 'app/services/feature-flags.service';

@Directive({
    selector: '[featureFlag]',
    standalone: false
})
export class FeatureFlagDirective implements OnInit {

  @Input() featureFlag: string;
  
  constructor(
    private vcRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private featureFlagsService: FeatureFlagsService
  ) { }
  
  ngOnInit(): void {
      if(
        this.featureFlagsService.flagsLoaded() &&
        this.featureFlagsService.isFlagEnabled(this.featureFlag)
      ){
        this.vcRef.createEmbeddedView(this.templateRef);
      }
  }

}
