import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { SharedFileHeaderDto } from 'app/api';

@Component({
    selector: 'reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss'],
    standalone: false
})
export class ReportsComponent implements OnInit, OnChanges {
	@Input() reports: SharedFileHeaderDto[];
	@Input() loading: boolean;

	constructor() {}
	ngOnInit(): void {
		this.mapReports();
	}

	ngOnChanges() {
		this.mapReports();
	}

	mapReports() {
		this.reports = this.reports?.map((item, index) => {
			return {
				...item,
				expanded: index === 0 ? true : false,
			};
		});
	}
}
