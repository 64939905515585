import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { environment } from 'environments/environment';

export const tinGermanValidator: ValidatorFn[] = [Validators.required, Validators.minLength(11), germanValidator];

export function germanValidator(control: AbstractControl): Record<string, boolean> | null {
	if (environment.production && !isValidGermanTIN(control.value)) {
		return { distinct: true };
	}
	return null;
}

function isValidGermanTIN(tin: string): boolean {
	if (!/^\d{11}$/.test(tin)) return false;

	const digits = tin.split('').map(Number);
	const firstTen = digits.slice(0, 10);
	const checkDigit = digits[10];

	if (!isValidStructure(digits)) return false;
	return calculateCheckDigit(firstTen) === checkDigit;
}

function isValidStructure(digits: number[]): boolean {
	if (digits.length !== 11) return false;
	if (digits[0] === 0) return false;

	return true;
}

function calculateCheckDigit(digits: number[]): number {
	if (digits.length !== 10 || digits.some(d => d < 0 || d > 9)) {
		throw new Error('Input must be an array of exactly 10 digits (0–9)');
	}

	let product = 10;

	for (let i = 0; i < 10; i++) {
		let sum = (digits[i] + product) % 10;
		if (sum === 0) sum = 10;
		product = (sum * 2) % 11;
	}

	let checkDigit = 11 - product;
	if (checkDigit === 10) checkDigit = 0;

	return checkDigit;
}
