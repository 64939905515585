import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-onboarding-stepper',
    templateUrl: './onboarding-stepper.component.html',
    styleUrls: ['./onboarding-stepper.component.scss'],
    standalone: false
})
export class OnboardingStepperComponent {
	@Input() activeStep = 1;
	@Input() loading = false;
	@Input() numberOfSteps = 6;
	@Input() buttonText = 'identification.stepper.button.text';
	@Output() backButtonClicked: EventEmitter<number | void> = new EventEmitter<number | void>();
	@Output() forwardButtonClicked: EventEmitter<number | void> = new EventEmitter<number | void>();
	@Output() lastStepButtonClicked: EventEmitter<void> = new EventEmitter<void>();

	stepBack() {
		if (this.activeStep > 1) {
			this.activeStep--;
			this.backButtonClicked.emit(this.activeStep);
		}
	}

	stepForward() {
		if (this.activeStep < this.numberOfSteps) {
			this.activeStep++;
			this.forwardButtonClicked.emit(this.activeStep);
		}
	}
}
