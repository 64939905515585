import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LoanPurposeDto, LoanPurposeOptionDto, PurposesHttpService } from 'app/api';
import { WhiteLabelClientSettingsService } from 'app/services/white-label-client-settings.service';
import { Observable, Subject, from } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap, takeUntil, toArray } from 'rxjs/operators';

@Component({
    selector: 'app-loan-purpose-list',
    templateUrl: './loan-purpose-list.component.html',
    styleUrls: ['./loan-purpose-list.component.scss', '../list-item/list-item.component.scss'],
    standalone: false
})
export class LoanPurposeListComponent implements OnInit, OnDestroy, OnChanges {
	@Input() isListView = false;
	@Input() showSubmitButton = true;
	@Input() purpose: LoanPurposeDto;
	@Input() loading: boolean;
	@Input() hasErrors: boolean;
	@Output() handleButtonClicked = new EventEmitter<{ isFormValid: boolean; data: LoanPurposeDto }>();

	purposes$: Observable<LoanPurposeOptionDto[]>;
	purposeForm: FormGroup;
	protected readonly destroy$ = new Subject();
	currencySuffix = 'kr';

	constructor(
		private formBuilder: FormBuilder,
		private translateService: TranslateService,
		private purposeHttpService: PurposesHttpService,
		private clientSettingsService: WhiteLabelClientSettingsService
	) {}

	ngOnInit(): void {
		const partnerSettings = this.clientSettingsService.getSettings();
		if (partnerSettings) {
			this.currencySuffix = partnerSettings.currencySuffix;
		}

		this.loadData();
	}

	loadData() {
		this.purposes$ = this.purposeHttpService.getLoanPurposes().pipe(
			switchMap(purposes =>
				from(purposes).pipe(
					map(purpose => {
						return {
							...purpose,
							text: 'purpose.' + purpose.code,
							description: 'loan-purpose.description.' + 'purpose.' + purpose.code,
						};
					}),
					toArray()
				)
			),
			shareReplay()
		);

		this.initializeForm();
	}

	ngOnChanges() {
		this.loadData();
	}

	initializeForm() {
		this.purposeForm = this.formBuilder.group({
			purpose: ['', { validators: [Validators.required] }],
			answers: [],
		});

		const initialPurpose$ = this.purposes$.pipe(
			map(purposes => purposes.find(purpose => purpose.code === this.purpose?.code))
		);
		initialPurpose$.subscribe(initialPurpose => this.purposeCtrl.setValue(initialPurpose));

		this.purposeCtrl.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe(purpose => {
			if (purpose) {
				this.purposeForm.setControl(
					'answers',
					this.formBuilder.array(
						purpose.questions?.map(q => {
							const initialAnswer = this.purpose?.answers.find(answer => answer.question_code === q.code);
							return q.required
								? this.formBuilder.control(initialAnswer ? initialAnswer.answer : '', Validators.required)
								: this.formBuilder.control(initialAnswer ? initialAnswer.answer : '');
						})
					)
				);
			}
		});
	}

	get purposeCtrl(): AbstractControl {
		return this.purposeForm.get('purpose');
	}

	get answersCtrl(): UntypedFormArray {
		return this.purposeForm.get('answers') as UntypedFormArray;
	}

	returnData(): LoanPurposeDto {
		const loanPurpose = {
			code: this.purposeCtrl.value.code,
			text: this.translateService.instant(this.purposeCtrl.value.text),
			answers: this.answersCtrl.value.map((answer, i) => {
				return {
					question_code: this.purposeCtrl.value.questions[i].code,
					question_text: this.translateService.instant('purpose.question.' + this.purposeCtrl.value.questions[i].code),
					answer: answer,
				};
			}),
		};
		return loanPurpose;
	}

	validateForm(): boolean {
		this.purposeForm.markAllAsTouched();
		return this.purposeForm.valid;
	}

	buttonClicked() {
		this.handleButtonClicked.emit({
			isFormValid: this.validateForm(),
			data: this.returnData(),
		});
	}

	handleOptionClick(option: LoanPurposeDto) {
		this.purposeCtrl.setValue(option);
	}

	ngOnDestroy() {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}
}
