import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss'],
    standalone: false
})
export class BadgeComponent implements OnInit {
	@Input() type = 'primary';

	constructor() {}

	ngOnInit() {}
}
