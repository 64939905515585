import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { expandRows, fadeInOut, smoothOpenClose } from 'app/animations/smooth.animations';
import { LoansHttpService, OrganizationEventDto } from 'app/api';
import { EventsService, OrganizationService } from 'app/services';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { OrganizationEventExtended } from '../../../helpers/events.helper';
import { LoanFilterLoan } from '../table-loan-filter/table-loan-filter.component';

@Component({
    selector: 'app-events-card-ls',
    templateUrl: './events-card-ls.component.html',
    animations: [smoothOpenClose, fadeInOut, expandRows],
    standalone: false
})
export class EventsCardLsComponent implements OnInit, OnChanges {
	@Input() events: OrganizationEventDto[];
	@Input() hideLoadMoreButton = false;
	@Input() loading: boolean;
	@Input() showLoanSelector = false;

	@Input() canLoadMore: boolean;
	@Input() expandOnLoad = true;

	@Output() clickedMore = new EventEmitter<void>();
	@Output() load = new EventEmitter<void>();
	@Output() loanFilterChange = new EventEmitter<string>();

	firstEventExpanded = false;
	mappedEvents: OrganizationEventExtended[] = [];
	availableLoans$: Observable<LoanFilterLoan[]>;
	lastAvailableLoans: LoanFilterLoan[] = [];

	constructor(
		private loansService: LoansHttpService,
		private organizationService: OrganizationService,
		private eventsService: EventsService,
		private translateService: TranslateService
	) {}

	ngOnInit(): void {
		const organizationId = this.organizationService.getOrganizationId();
		const creditId = this.organizationService.getCreditId();

		this.availableLoans$ = this.loansService.getAllLoans(organizationId, creditId).pipe(
			map(loans => {
				return loans.map(loan => ({
					id: loan.loanId,
					name: loan.displayId,
				}));
			}),
			shareReplay(1)
		);

		// Combine loans (async) and events (static) for processing
		this.availableLoans$?.subscribe(loans => {
			this.updateMappedEvents(loans, this.events);
		});

		this.translateService.onLangChange.subscribe(_ => {
			this.updateMappedEvents(this.lastAvailableLoans, this.events);
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.events) {
			this.updateMappedEvents(this.lastAvailableLoans, this.events);
		}
	}

	private updateMappedEvents(loans: LoanFilterLoan[] = [], events: OrganizationEventDto[] = []): void {
		this.lastAvailableLoans = loans; // Cache the latest loans
		this.mappedEvents = this.eventsService.formatLSEventsForDisplay(events, loans);
	}

	handleLoanFilterChange(selectedLoanId: string) {
		this.loanFilterChange.emit(selectedLoanId);
	}

	visibilityChangeHandler(visibility: 'visible' | 'hidden') {
		if (
			this.expandOnLoad &&
			!!this.mappedEvents &&
			this.mappedEvents.length > 0 &&
			!this.firstEventExpanded &&
			visibility === 'visible'
		) {
			this.mappedEvents[0].expanded = true;
			this.firstEventExpanded = true;
		}
	}
}
