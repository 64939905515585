import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { smoothOpenClose } from 'app/animations/smooth.animations';
import { CountryISOCode, TrapetsHttpService } from 'app/api';
import { CountryNameService } from 'app/services/country-name.service';
import { atLeastOneCountryValidator } from 'app/shared/validators';
import { Subject, from } from 'rxjs';
import {
	debounceTime,
	distinctUntilChanged,
	filter,
	map,
	shareReplay,
	switchMap,
	takeUntil,
	toArray,
} from 'rxjs/operators';

@Component({
    selector: 'high-risk-countries',
    templateUrl: './high-risk-countries.component.html',
    styleUrls: ['./high-risk-countries.component.scss'],
    animations: [smoothOpenClose],
    standalone: false
})
export class HighRiskCountriesComponent implements OnInit, OnDestroy {
	@Input() initialDoesOperateOutsideEu: boolean;
	@Input() initialHighRiskCountries: CountryISOCode[];
	hrcForm: FormGroup;
	protected readonly destroy$ = new Subject();

	constructor(
		private formBuilder: FormBuilder,
		protected trapetsService: TrapetsHttpService,
		protected countryNameService: CountryNameService
	) {}

	ngOnInit(): void {
		this.initializeForm();

		const highRiskCountries$ = this.trapetsService.getHighRiskCountries().pipe(
			switchMap(countries =>
				from(countries).pipe(
					map(country => {
						return { code: country, name: this.countryNameService.getNameByCode(country) };
					}),
					toArray()
				)
			),
			map(countries =>
				countries.sort((a, b) => {
					const nameA = a.name.charAt(0);
					const nameB = b.name.charAt(0);
					if (nameA < nameB) {
						return -1;
					}
					if (nameA > nameB) {
						return 1;
					}
					return 0;
				})
			),
			shareReplay(1),
			takeUntil(this.destroy$)
		);

		highRiskCountries$.subscribe(countries => {
			this.setFormCountries(countries);

			const noHighRiskCountriesChecked$ = this.noCountries.valueChanges.pipe(
				filter(checked => checked),
				takeUntil(this.destroy$)
			);

			//uncheck all selected countries if "We do not conduct business with any of the countries above" checkbox is clicked
			noHighRiskCountriesChecked$.subscribe(_ => {
				this.highRiskCountries.controls.forEach(country => {
					country.patchValue({ checked: false });
				});
				this.highRiskCountries.updateValueAndValidity();
			});

			//uncheck "We do not conduct business with any of the countries above" checkbox if any of the high risk countries are selected
			this.highRiskCountries.valueChanges
				.pipe(
					debounceTime(200),
					filter(_ => this.noCountries.value),
					map(countries => countries.find(country => country.checked)),
					filter(checkedCountry => checkedCountry !== undefined),
					distinctUntilChanged()
				)
				.subscribe(_ => {
					this.noCountries.setValue(false);
				});
		});

		//if "Do you conduct business with countries outside Scandinavia/EU?" answer is "No", uncheck all selected high risk countries
		this.businessOutsideEU.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
			if (!value) {
				this.highRiskCountries.controls.forEach(country => {
					country.patchValue({ checked: false });
				});
			}
			this.highRiskCountries.updateValueAndValidity();
		});
	}

	initializeForm() {
		this.hrcForm = this.formBuilder.group({
			businessOutsideEU: [this.initialDoesOperateOutsideEu, { validators: [Validators.required] }],
			highRiskCountries: [], //this control values and validators are set in setFormCountries()
			noCountries: [this.initialDoesOperateOutsideEu && this.initialHighRiskCountries.length === 0],
		});
	}

	setFormCountries(countries: { code: CountryISOCode; name: string }[]) {
		this.hrcForm.setControl(
			'highRiskCountries',
			this.formBuilder.array(
				countries.map(c =>
					this.formBuilder.group({
						name: [c.name],
						code: [c.code],
						checked: this.initialHighRiskCountries.includes(c.code),
					})
				),
				{
					validators: [
						atLeastOneCountryValidator(
							() => this.businessOutsideEU.value,
							() => this.noCountries.value
						),
					],
				}
			)
		);
	}

	validateForm() {
		this.hrcForm.markAllAsTouched();
		return this.hrcForm.valid;
	}

	returnData(): { highRiskCountries: CountryISOCode[]; doesOperateOutsideEu: boolean } {
		return {
			highRiskCountries: this.highRiskCountries.value.filter(c => c.checked).map(c => c.code),
			doesOperateOutsideEu: this.businessOutsideEU.value,
		};
	}

	get businessOutsideEU(): AbstractControl {
		return this.hrcForm.get('businessOutsideEU');
	}

	get highRiskCountries(): UntypedFormArray {
		return this.hrcForm.get('highRiskCountries') as UntypedFormArray;
	}

	get noCountries(): AbstractControl {
		return this.hrcForm.get('noCountries');
	}

	ngOnDestroy() {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}
}
