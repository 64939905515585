import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FeatureFlagsService } from './feature-flags.service';

@Injectable({
	providedIn: 'root',
})
export class PartnerContextService {
	constructor(private featureFlags: FeatureFlagsService, private router: Router) {}

	updateContextForCurrentRoute(url: string): Observable<unknown> {
		const parts = url.split('/');
		const isWhiteLabelRoute = parts[1] === 'p';
		const partnerKey = parts[2];

		return this.featureFlags.updateContext({
			properties: {
				partnerKey: isWhiteLabelRoute && partnerKey ? partnerKey : undefined,
			},
		});
	}
}
