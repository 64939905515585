import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentIntervalType, RepaymentType, WithdrawalRequestDto } from 'app/api';
import { requestApproved } from 'app/services/withdrawal-request-functions';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-application-summary',
    templateUrl: './application-summary.component.html',
    styleUrls: ['./application-summary.component.scss'],
    standalone: false
})
export class ApplicationSummaryComponent implements OnInit {
	@Input({ required: true }) pendingRequest: WithdrawalRequestDto;
	@Input() currency: string;
	@Input() canCancelApplication: boolean;
	@Input() contractsSigned: boolean;
	@Input() isCardBasedLending: boolean;
	@Input() paymentType: RepaymentType;
	@Output() cancelApplication = new EventEmitter<void>();

	protected readonly destroy$ = new Subject();

	ngOnInit(): void {}

	get hasPreviousLoan(): boolean {
		return this.pendingRequest && this.pendingRequest.current_principal_due.amount > 0;
	}

	get isRequestApproved() {
		return requestApproved(this.pendingRequest);
	}

	paysWeekly() {
		return (
			this.pendingRequest?.payment_interval.type === PaymentIntervalType.Weekly ||
			this.pendingRequest?.payment_interval.type === PaymentIntervalType.WeeklySimple
		);
	}

	paysDaily() {
		return (
			this.pendingRequest?.payment_interval.type === PaymentIntervalType.Daily ||
			this.pendingRequest?.payment_interval.type === PaymentIntervalType.Bankday
		);
	}

	paysMonthly() {
		return this.pendingRequest?.payment_interval.type === PaymentIntervalType.Monthly;
	}
}
