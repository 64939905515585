//TODO: copied from kontist theme
//TODO: add vivid theme variables
export const vividVariables = {
	'font-higlight': 'Satoshi',
	'font-header': 'Satoshi',
	'font-body': 'Satoshi',
	'primary-100': '#F4EDFF',
	'primary-200': '#E5D6FD',
	'primary-400': '#8A47F7',
	'primary-500': '#7D33F6',
	'primary-700': '#33254C',
	'secondary-100': '#F4EDFF',
	'secondary-200': '#E5D6FD',
	'secondary-400': '#8A47F7',
	'secondary-500': '#7D33F6',
	'secondary-900': '#33254C',

	'checkbox-100': '#7D33F6',
	'checkbox-300': '#E5D6FD',
	'checkbox-500': '#7D33F6',
	'radio-100': '#E5D6FD',
	'radio-500': '#7D33F6',

	'rounded-box': '16px',
	'rounded-notification': '4px',

	'min-width': 'fit-content',

	'highlight-light': '#FFFFFF',
	'highlight-border': '#EEEEEE',
	'slider-color': '#FFFFFF',
	'slider-border-color': '#7D33F6',

	//Box card styles
	'box-background': '#7D33F6',
	'box-info': '#FFFFFF',
	'box-border': '#7D33F6',

	//Box card styles - secondary
	'box-background-light': '#F4EDFF',
	'box-info-light': '#333333',
	'box-border-light': '#F4EDFF',

	separator: '#E5D6FD',

	'input-phone-prefix': '#EDECEE',
	spinner: '#33254C',

	highlighted: '#7D33F6',
	title: '#333333',
	paragraph: '#333333',

	dark: '#33254C',
	brand: '#F4EDFF',

	'footer-background': '#FAFAFA',

	'common-padding': '16px',
	'common-margin': '16px',
	'common-gap': '32px',

	// BUTTON STYLES

	// primary button
	'button-color': '#7D33F6',
	'button-color-hover': '#6121D9',
	'button-text': '#FFFFFF',
	'button-text-hover': '#FFFFFF',
	'rounded-button': '24px',
	'padding-button': '12px 24px',
	'border-button-color': '#7D33F6',

	// secondary button
	'button-color-secondary': '#f3f3f3',
	'button-text-secondary': '#333333',
	'button-text-secondary-hover': '#1e1e1e',
	'rounded-button-secondary': '24px',
	'padding-button-secondary-outlined': '10px 24px',
	'button-border-secondary-outlined': '#e6e6e6',
	'button-border-secondary-hover': '#cccccc',
	// --------------------------------------- //

	// loading animations
	'big-loader': [
		'#7D33F6',
		'#7D33F6',
		'#7D33F6',
		'#7D33F6',
		'#F4EDFF',
		'#F4EDFF',
		'#F4EDFF',
		'#F4EDFF',
		'#7D33F6',
		'#7D33F6',
		'#7D33F6',
		'#7D33F6',
		'#F4EDFF',
		'#F4EDFF',
		'#F4EDFF',
		'#F4EDFF',
		'#7D33F6',
	],
	'small-loader': ['#7D33F6', '#7D33F6', '#7D33F6', '#F4EDFF', '#F4EDFF', '#F4EDFF'],
};
