<app-modal-card *ngIf="identifySession$ | async as identifySession" [visible]="visible" [closable]="false">
	<div [smoothHeight]="identifySession.hint_code">
		<h1 class="mb-6 text-xl text-center">{{ 'bankid-modal.heading' | translate }}</h1>

		<div *ngIf="identifySession.hint_code === 'OpenBankID'" class="flex flex-col items-center mb-6">
			<img src="assets/shared/icons/phone-bankid-open.svg" alt="BankID open app" class="h-20 mb-4" />
			<h4>{{ 'bankid-modal.title.OPEN_BANKDID' | translate }}</h4>
		</div>
		<div *ngIf="identifySession.hint_code === 'ScanQR'" class="flex flex-col items-center mb-6">
			<img src="assets/shared/icons/phone-bankid-qr.svg" alt="BankID scan QR" class="h-20 mb-4" />
			<h4 class="mb-4">{{ 'bankid-modal.title.ScanQR' | translate }}</h4>
			<div class="flex items-center justify-center border-dark-grey border-dashed border-5">
				<qrcode style="margin: 0 auto" [qrdata]="identifySession.qr_code"></qrcode>
			</div>
		</div>
		<div *ngIf="identifySession.hint_code === 'UserSign'" class="flex flex-col items-center mb-6">
			<img src="assets/shared/icons/phone-bankid-pin.svg" alt="BankID enter PIN" class="h-20 mb-4" />
			<h4>{{ 'bankid-modal.title.UserSign' | translate }}</h4>
			<p class="max-w-sm mt-2 text-center" translate="bankid-modal.hint.UserSign"></p>
		</div>
		<div *ngIf="identifySession.status === 'Failed'" class="flex flex-col items-center my-6">
			<app-notification type="error">{{ 'bankid-modal.hint.' + identifySession.hint_code | translate }}</app-notification>
		</div>
		<div class="flex items-center my-6 flex-column">
			<app-loader></app-loader>
		</div>
		<div *ngIf="identifySession.status === 'Complete'" class="flex flex-col items-center my-6">
			<app-notification type="success">{{ 'bankid-modal.status.Complete' | translate }}</app-notification>
		</div>
		<div class="flex flex-col items-center">
			<app-button *ngIf="(identifySession.hint_code === 'OpenBankID') && autoAppSwitch" class="mb-4" (action)="openBankIDApp()">
				{{ 'bankid-modal.button.start-bankid' | translate }}
			</app-button>
			<a *ngIf="identifySession.hint_code === 'OpenBankID'" class="mb-4 link" (click)="switchDevice()">{{ 'bankid-modal.button.bankid-other-device' | translate }}</a>
			<a *ngIf="identifySession.hint_code === 'ScanQR'" class="mb-4 link" (click)="switchDevice()">{{ 'bankid-modal.button.bankid-this-device' | translate }}</a>
			<app-button *ngIf="identifySession.status === 'Failed'" class="mb-4" (action)="retryBankID()">{{ 'bankid-modal.button.retry' | translate }}</app-button>
			<a class="mb-4 link" (click)="closeModal()">{{ 'bankid-modal.button.cancel' | translate }}</a>
		</div>
	</div>
</app-modal-card>

<dev-ssn-modal *ngIf="devSSNModalVisible" [visible]="devSSNModalVisible" (close)="devSSNModalVisible = false" (continue)="devSSNEntered($event)"></dev-ssn-modal>
