import { Injectable } from '@angular/core';
import { DEFAULT_APP_LANGUAGE } from 'app/app.contants';
import { AppConfig } from 'app/bootstrapping/appconfig';
import { User } from 'app/models/user';
import { sha256 } from 'js-sha256';
import smartlookClient from 'smartlook-client';
import { LocalStorageService } from './local-storage.service';
import { OrganizationService } from './organization.service';
import { UserService } from './user.service';

@Injectable({
	providedIn: 'root',
})
export class UserTrackingService {
	constructor(
		private userService: UserService,
		private organizationService: OrganizationService,
		private localStorage: LocalStorageService,
		private appConfig: AppConfig
	) {
		if (!window.location.origin.includes('localhost')) {
			smartlookClient.init(this.appConfig.smartlookClientKey);
		}
	}

	organizationId: string;
	user: User;
	trackingUser: TrackingUser;
	use_loan_servicing: boolean;

	public identify() {
		this.organizationId = this.organizationService.getOrganizationId();
		this.user = this.userService.getUser();
		try {
			if (this.user.email == null || this.user.email == '') return;

			this.trackingUser = {
				id: this.user.id,
				organizationId: this.organizationId,
				locale: this.localStorage.get('chosenLocale') || DEFAULT_APP_LANGUAGE,
				use_loan_servicing: false,
				lsMigrationInProgress: false,
			};

			const params = this.getUserParams(this.trackingUser);

			if (params) {
				if (smartlookClient.initialized())
					smartlookClient.identify(this.generateUserHash(this.user.email, this.user.id), params);
			}
		} catch {}
	}

	public anonymize() {
		if (smartlookClient.initialized()) smartlookClient.anonymize();
	}

	public trackEvent(name: string, properties: Record<string, string | number | boolean>) {
		if (smartlookClient.initialized()) smartlookClient.track(name, properties);
	}

	public setUserVars(userVars: Record<string, string | number | boolean>) {
		if (this.user) {
			const params = this.getUserParams(this.user);
			if (smartlookClient.initialized())
				smartlookClient.identify(this.generateUserHash(this.user.email, this.user.id), { ...params, ...userVars });
		}
	}

	private getUserParams(user: TrackingUser): Record<string, string | number | boolean> {
		if (!user) return;

		const params = {};
		if (user.id) params['id'] = user.id;
		if (user.organizationId) params['organizationId'] = user.organizationId;
		if (user.locale) params['locale'] = user.locale;
		if (user.use_loan_servicing) params['use_loan_servicing'] = user.use_loan_servicing;
		if (user.lsMigrationInProgress) params['lsMigrationInProgress'] = user.lsMigrationInProgress;

		return params;
	}

	private generateUserHash(email, userId) {
		return sha256(email + userId)
			.toString()
			.substr(0, 24);
	}
}

export class TrackingUser {
	id?: string;
	organizationId?: string;
	locale?: string;
	use_loan_servicing?: boolean;
	lsMigrationInProgress?: boolean;
}
