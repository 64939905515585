import { ValidatorFn, Validators } from '@angular/forms';

//Phone validator for mobile phone number that is entered through inputs
//Validates Finnish mobile phone numbers
//eg. +358 40 123 1234

export const finnishMobilePhoneValidator: ValidatorFn[] = [
	Validators.required,
	Validators.pattern(/^(?:\+358|0)(4\d|457|50)\d{6,7}$/),
];
