import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { fadeInOut } from 'app/animations/smooth.animations';
import { Provider } from 'app/api-scraping';

@Component({
    selector: 'app-bank-info',
    templateUrl: './bank-info.component.html',
    styleUrls: ['./bank-info.component.scss'],
    animations: [
        fadeInOut,
        trigger('itemAnimation', [
            transition(':enter', [
                style({ height: '0', opacity: 0 }),
                animate('300ms ease-out', style({ height: '*', opacity: 1 })),
            ]),
            transition(':leave', [animate('300ms ease-in', style({ height: '0', opacity: 0 }))]),
        ]),
    ],
    standalone: false
})
export class BankInfoComponent {
	@Input() bankInfoForm: FormGroup;
	@Input() searchedBankOptions: Provider[] = [];
	@Input() popularBankOptions: Provider[] = [];

	@Output() bankClicked = new EventEmitter<Provider>();

	isSearchActive = false;

	onBankClicked(bank: Provider) {
		this.bankClicked.emit(bank);
	}

	get selectedBank(): AbstractControl {
		return this.bankInfoForm.get('selectedBank');
	}

	get search(): AbstractControl {
		return this.bankInfoForm.get('search');
	}

	handleSearchOnFocus() {
		this.isSearchActive = true;
	}

	handleSearchOnBlur() {
		if (this.search.value.length === 0) {
			setTimeout(() => {
				this.isSearchActive = false;
			}, 500);
		}
	}
}
