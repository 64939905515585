import { Component, OnInit, Input } from '@angular/core';
import { rotate180deg, smoothOpenClose } from 'app/animations/smooth.animations';
import { AnalyticsService } from 'app/services/analytics.service';

@Component({
    selector: 'app-faq-item',
    templateUrl: './faq-item.component.html',
    styleUrls: ['./faq-item.component.scss'],
    animations: [smoothOpenClose, rotate180deg],
    standalone: false
})
export class FaqItemComponent implements OnInit {
	@Input() open = false;
	@Input() key;
	@Input() question;
	@Input() answer;

	constructor(private analyticsService: AnalyticsService) {}

	ngOnInit() {}

	toggle() {
		this.open = !this.open;
		if (this.open) this.analyticsService.registerEvent('home.faq.click', this.key);
	}
}
