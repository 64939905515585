import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WhiteLabelClientSettingsService } from 'app/services/white-label-client-settings.service';

@Component({
    selector: 'app-remove-modal',
    templateUrl: './remove-modal.component.html',
    styleUrls: ['./remove-modal.component.scss'],
    standalone: false
})
export class RemoveModalComponent implements OnInit {
	@Input() visible = false;
	@Input() title: string;
	@Input() text: string;
	@Input() removeBtnText: string;
	@Input() removalInProgress = false;
	@Output() close = new EventEmitter<void>();
	@Output() remove = new EventEmitter<void>();
	partner: string | null = null;

	constructor(private route: ActivatedRoute) {}

	ngOnInit(): void {
		this.route.parent.paramMap.subscribe(params => {
			this.partner = params.get('partner');
		});
	}
}
