import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-back-button',
    templateUrl: './back-button.component.html',
    styleUrls: ['./back-button.component.scss'],
    standalone: false
})
export class BackButtonComponent {
	@Input() visible = true;
	@Input() text = 'back-button.default';
	@Output() back = new EventEmitter<void>();
}
