import { ComponentRef, Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { ThreeDotsComponent } from '../components/three-dots/three-dots.component';

@Directive({
    selector: '[loadingDots]',
    standalone: false
})
export class LoadingDotsDirective {
	loadingComponent: ComponentRef<ThreeDotsComponent>;

	constructor(private vcRef: ViewContainerRef, private templateRef: TemplateRef<any>) {}

	@Input() set loadingDots(obj: any) {
		if (!obj) {
			this.loadingComponent = this.vcRef.createComponent(ThreeDotsComponent);
		} else {
			this.vcRef.clear();
			this.vcRef.createEmbeddedView(this.templateRef);
		}
	}
}
