import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { OtherLoanRequest } from 'app/api';
import { WhiteLabelClientSettingsService } from 'app/services/white-label-client-settings.service';

@Component({
    selector: 'add-lender',
    templateUrl: './add-lender.component.html',
    styleUrls: ['./add-lender.component.scss'],
    standalone: false
})
export class AddLenderComponent implements OnInit {
	@Input() visible = false;
	@Input() currency: string;
	@Output() close = new EventEmitter<void>();
	@Output() add = new EventEmitter<any>();
	lenderForm: FormGroup;
	@Input() selectedLender: string;
	maxDuration = 60;

	constructor(
		protected formBuilder: FormBuilder,
		protected route: ActivatedRoute,
		protected clientSettingsService: WhiteLabelClientSettingsService
	) {}

	get amount(): AbstractControl {
		return this.lenderForm.get('amount');
	}

	get duration(): AbstractControl {
		return this.lenderForm.get('duration');
	}

	ngOnInit() {
		this.initializeForm();
	}

	initializeForm() {
		this.lenderForm = this.formBuilder.group({
			amount: ['', { validators: [Validators.required] }],
			duration: ['', { validators: [Validators.required, Validators.max(this.maxDuration)] }],
		});
	}

	addLender() {
		this.lenderForm.markAllAsTouched();
		if (this.lenderForm.valid) {
			const lender: OtherLoanRequest = {
				lender: this.selectedLender,
				remainingAmount: this.amount.value,
				remainingDurationInMonths: this.duration.value,
			};
			this.add.emit(lender);
		}
	}
}
