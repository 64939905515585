import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'loading-modal',
    templateUrl: './loading-modal.component.html',
    styleUrls: ['./loading-modal.component.scss'],
    standalone: false
})
export class LoadingModalComponent implements OnInit {

  @Input() visible: boolean;
  @Input() title: string;
  @Input() text: string;

  constructor() { }

  ngOnInit(): void {
  }

}
