import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'localDate',
    standalone: false
})
export class LocalDatePipe implements PipeTransform {
	constructor(private translate: TranslateService) {}

	transform(value: string, format: string, locale?: string): string {
		if (!value) {
			return '';
		}
		if (!format) {
			format = 'shortDate';
		}
		if (!locale) {
			locale = this.translate.currentLang;
		}
		return formatDate(value, format, locale);
	}
}
