import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-fetch-credit-terms-modal',
    templateUrl: './fetch-credit-terms-modal.component.html',
    styleUrls: ['./fetch-credit-terms-modal.component.scss'],
    standalone: false
})
export class FetchCreditTermsModalComponent implements OnInit, OnDestroy {
	@Input() visible: boolean;
	@Input() errorMessage: string;
	@Output() close = new EventEmitter<void>();
	private readonly destroy$ = new Subject<string>();

	constructor() {}

	ngOnInit(): void {}

	ngOnDestroy() {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}
}
