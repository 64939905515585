import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-status-step',
    templateUrl: './status-step.component.html',
    styleUrls: ['./status-step.component.scss'],
    standalone: false
})
export class StatusStepComponent {
	@Input() title: string;
	@Input() iconName: string;
	@Input() iconPath: string;
	@Input() iconId: string;
	@Input() step: string;
	@Input() currentStep?: string;
	@Input() isLastStep = false;
	@Input() bgColor?: string = 'bg-almost-black';
	@Input() textColor?: string = 'text-almost-black';
}
