import { Pipe, PipeTransform } from '@angular/core';
import { LocalStorageService } from '@services';
import { MoneyDto } from 'app/api';
import { FormatMoney } from './money.pipe';

@Pipe({
    name: 'asMoney',
    standalone: false
})
export class AsMoneyPipe implements PipeTransform {
	constructor(private cacheService: LocalStorageService) {}

	transform(input: number, currency: string, numberOfDecimals?: number): string {
		const money: MoneyDto = { amount: input, currency: currency } as MoneyDto;
		return FormatMoney(this.cacheService, money, numberOfDecimals);
	}
}
