import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class NonceService {
	private nonce: string;

	constructor() {
		// Get nonce from root app element's ngCspNonce attribute
		const appRoot = document.querySelector('app-root');
		this.nonce = appRoot?.getAttribute('ngCspNonce') || '';
	}

	setNonceForElement(element: HTMLElement) {
		if (this.nonce) {
			element.setAttribute('nonce', this.nonce);
		}
	}
}
