import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'simpleMarkdown',
    standalone: false
})
export class SimpleMarkdownPipe implements PipeTransform {
	transform(value: string, classList: object): string {
		if (value == null) {
			return null;
		}
		if (classList == null) classList = {};
		let text = value.toString();

		text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
		text = text.replace(/\*(.*?)\*/g, '<span class="' + classList['*'] + '">$1</span>');

		return text;
	}
}
