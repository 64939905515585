import { Component, Input } from '@angular/core';
import { FixedLoanPriceResponseDto, MoneyDto, PaymentIntervalType } from 'app/api';
import { MoneyCalculatorService } from 'app/services/money-calculator.service';

@Component({
    selector: 'app-new-loan-summary',
    templateUrl: './new-loan-summary.component.html',
    styleUrl: './new-loan-summary.component.scss',
    standalone: false
})
export class NewLoanSummaryComponent {
	@Input() offer: FixedLoanPriceResponseDto;
	@Input() offerStale: boolean;
	@Input() paymentIntervalType: PaymentIntervalType;
	@Input() hasPreviousLoan: boolean;
	@Input() previousPayment: MoneyDto;

	constructor(private moneyCalculator: MoneyCalculatorService) {}

	get totalPayment(): MoneyDto {
		if (!this.offer?.standard_payment || !this.previousPayment) {
			return null;
		}
		return this.moneyCalculator.add(this.offer?.standard_payment, this.previousPayment);
	}
}
