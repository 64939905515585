import { Component, OnInit } from '@angular/core';
import { SUPPORT_PHONE_NUMBER } from 'app/my-froda/my-froda.constants';

@Component({
    selector: 'contact-card',
    templateUrl: './contact-card.component.html',
    styleUrls: ['./contact-card.component.scss'],
    standalone: false
})
export class ContactCardComponent implements OnInit {
	supportPhoneNumber = SUPPORT_PHONE_NUMBER;

	constructor() {}

	ngOnInit(): void {}
}
