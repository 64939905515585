import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { DefaultSpinnerComponent } from './assets/spinner/default-spinner/default-spinner.component';
import { YabieSpinnerComponent } from './assets/spinner/yabie-spinner/yabie-spinner.component';
import { TailwindThemeService } from './tailwind-theme.service';

export function initTailwindThemeConfig(tailwindThemeService: TailwindThemeService) {
	return () => tailwindThemeService.loadConfig();
}

@NgModule({
	imports: [CommonModule],
	providers: [
		TailwindThemeService,
		{
			provide: APP_INITIALIZER,
			useFactory: initTailwindThemeConfig,
			deps: [TailwindThemeService],
			multi: true,
		},
	],
	declarations: [YabieSpinnerComponent, DefaultSpinnerComponent],
})
export class TailwindThemeModule {
	static forRoot(): ModuleWithProviders<TailwindThemeModule> {
		return {
			ngModule: TailwindThemeModule,
			providers: [TailwindThemeService],
		};
	}
}
