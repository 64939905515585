import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { smoothHeight } from 'app/animations/smooth.animations';
import { BankIdAction } from 'app/models/bankIdAction';
import { BankIdUserInfo } from 'app/models/bankIdUserInfo';
import { BaseUrlService } from 'app/services/base-url.service';
import { DeviceService } from 'app/services/device.service';
import { IdentityService } from 'app/shared/auth/identity.service';
import { environment } from 'environments/environment';
import { Observable, Subject, of, throwError, timer } from 'rxjs';
import {
	catchError,
	exhaustMap,
	filter,
	map,
	mergeMap,
	publishReplay,
	refCount,
	retryWhen,
	switchMap,
	take,
	takeUntil,
	takeWhile,
	tap,
} from 'rxjs/operators';

@Component({
    selector: 'bankid-identify-modal',
    templateUrl: './bankid-identify-modal.component.html',
    styleUrls: ['./bankid-identify-modal.component.scss'],
    animations: [smoothHeight],
    standalone: false
})
export class BankidIdentifyModalComponent implements OnInit, OnDestroy {
	@Input() sessionId;
	@Input() autoStartToken;
	@Input() visible = false;
	@Input() action: BankIdAction = BankIdAction.Auth;
	@Input() redirectEnabled = true;
	@Input() autoAppSwitch = true;
	@Output() close = new EventEmitter<void>();
	@Output() completed = new EventEmitter<BankIdUserInfo | void>();
	@Output() retry = new EventEmitter<void>();
	@Output() failed = new EventEmitter<string>();
	@Output() failedError = new EventEmitter<string>();
	private readonly destroy$ = new Subject<string>();
	identifySession$: Observable<any>;
	codeVerifier: string;
	devSSN: string;
	devSSNModalVisible = false;

	constructor(
		private identityService: IdentityService,
		private router: Router,
		private route: ActivatedRoute,
		private deviceService: DeviceService,
		private baseUrlService: BaseUrlService
	) {}

	ngOnInit(): void {
		this.route.queryParams.subscribe(params => {
			if (params['verifier']) this.codeVerifier = params['verifier'];
			if (!environment.production && !!params['devSSN']) this.devSSN = params['devSSN'];
			if (!environment.production && !params['devSSN']) this.devSSNModalVisible = true;
		});

		const hidden = this.getHiddenPropertyName();

		this.identifySession$ = timer(0, 2000).pipe(
			filter(_ => !document[hidden]),
			filter(_ => !this.devSSNModalVisible),
			exhaustMap(_ => {
				if (this.action == BankIdAction.Auth) {
					if (this.devSSN) {
						return this.identityService.pollBankIDAuthResponseWithDevSSN(this.sessionId, this.devSSN);
					} else {
						return this.identityService.pollBankIDAuthResponse(this.sessionId);
					}
				} else if (this.action == BankIdAction.Sign) {
					if (this.devSSN) {
						return this.identityService.pollBankIDSignResponseWithDevSSN(this.sessionId, this.devSSN);
					} else {
						return this.identityService.pollBankIDSignResponse(this.sessionId);
					}
				}
			}),
			retryWhen(errors => errors.pipe(mergeMap((error, i) => (i > 10 ? throwError(error) : timer(1000))))),
			catchError(error => of({ status: 'Failed', hint_code: 'StartFailed', error })),
			map(session => {
				let hint_code = (session as any).hint_code;
				const status = (session as any).status;
				if (status === 'Pending') {
					if (this.deviceService.isOtherDevice()) {
						if (hint_code === 'OutstandingTransaction' || hint_code === 'Started') {
							hint_code = 'ScanQR';
						}
					} else if (this.deviceService.isSameDevice()) {
						if (hint_code === 'OutstandingTransaction' || hint_code === 'Started') hint_code = 'OpenBankID';
					}
				}
				return { ...session, hint_code };
			}),
			takeWhile(session => session.status !== 'Complete', true),
			publishReplay(1),
			refCount()
		);

		const completedSession$ = this.identifySession$.pipe(
			filter(session => (session as any).status === 'Complete'),
			take(1),
			takeUntil(this.destroy$)
		);

		if (this.action === BankIdAction.Auth) {
			const userInfo$ = completedSession$.pipe(
				tap(_ => (this.identityService.loginSessionId = this.sessionId)),
				map(session => (session as any).code),
				switchMap(code =>
					this.identityService.getIdentityToken(code, this.codeVerifier).pipe(
						tap(response => (this.identityService.accessToken = response.access_token)),
						tap(response => {
							if (response.id_token) this.identityService.idToken = response.id_token;
						}),
						map(response => {
							if (response.id_token) {
								return this.identityService.getUserInfoFromToken();
							} else {
								return;
							}
						}),
						catchError(err => {
							this.failed.emit('IdentityTokenError');
							return throwError(err);
						})
					)
				),
				takeUntil(this.destroy$)
			);

			userInfo$.subscribe(userInfo => this.completed.emit(userInfo));
		} else if (this.action === BankIdAction.Sign) {
			completedSession$.subscribe(_ => this.completed.emit());
		}

		this.trackBankIDEvents();
	}

	openBankIDApp() {
		const baseUrl = 'bankid:///';
		let launchUrl = baseUrl + '?autostarttoken=' + this.autoStartToken;

		if (this.redirectEnabled && this.isiOS()) {
			const returnUrl = this.constructReturnUrl();
			launchUrl += '&redirect=' + encodeURIComponent(returnUrl);
		} else launchUrl += '&redirect=';

		document.location.href = launchUrl;
	}

	private constructReturnUrl() {
		return (
			this.baseUrlService.getClientBaseUrl() +
			this.router.url +
			this.getQuerySeparator() +
			'identify_session_id=' +
			this.sessionId +
			'&verifier=' +
			this.identityService.codeVerifier
		);
	}

	closeModal() {
		this.close.emit();
	}

	retryBankID() {
		this.retry.emit();
	}

	switchDevice() {
		this.deviceService.switchDevice();
		this.retry.emit();
	}

	private isiOS() {
		const userAgent = navigator.userAgent;
		return /iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream;
	}

	private trackBankIDEvents() {
		this.identifySession$
			.pipe(
				filter(session => (session as any).status === 'Failed'),
				take(1),
				takeUntil(this.destroy$)
			)
			.subscribe(session => {
				this.failedError.emit(session.error);
				this.failed.emit(session.hint_code);
			});
	}

	private getHiddenPropertyName(): string {
		if (typeof (document as any).hidden !== 'undefined') {
			return 'hidden';
		} else if (typeof (document as any).msHidden !== 'undefined') {
			return 'msHidden';
		} else if (typeof (document as any).webkitHidden !== 'undefined') {
			return 'webkitHidden';
		}
	}

	private getQuerySeparator(): string {
		let querySeparator = '&';
		if (Object.keys(this.route.snapshot.queryParams).length === 0) querySeparator = '?';
		return querySeparator;
	}

	devSSNEntered(devSSN: string) {
		this.devSSN = devSSN;
		this.devSSNModalVisible = false;
	}

	ngOnDestroy() {
		this.destroy$.next(undefined);
		this.destroy$.complete();
	}
}
