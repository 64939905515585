import { Injectable } from '@angular/core';
import { SentryService } from './sentry.service';

@Injectable({
	providedIn: 'root',
})
export class CspService {
	constructor(private sentryService: SentryService) {}

	init() {
		document.addEventListener('securitypolicyviolation', e => {
			this.handleCspViolation(e);
		});
	}

	private handleCspViolation(e: SecurityPolicyViolationEvent) {
		const violationType = e.violatedDirective.split('-')[0];

		this.sentryService.setTag('csp_violation_type', violationType);
		this.sentryService.setTag('csp_directive', e.violatedDirective);
		this.sentryService.setExtra('blocked_uri', e.blockedURI);
		this.sentryService.setExtra('document_uri', e.documentURI);
		this.sentryService.setExtra('referrer', document.referrer);
		this.sentryService.setExtra('source_file', e.sourceFile);
		this.sentryService.setExtra('disposition', e.disposition);
		this.sentryService.setExtra('original_policy', e.originalPolicy);

		this.sentryService.captureMessage(`CSP Violation: ${violationType}`, 'warning');
	}
}
