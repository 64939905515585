import { Pipe, PipeTransform } from '@angular/core';
import { MoneyDto } from 'app/api';
import { LocalStorageService } from 'app/services';

type CurrencyVisibility = 'hideCurrency' | 'showCurrency';
type ConvertNegative = 'convertToPositive' | 'keepNegative';

@Pipe({
    name: 'money',
    standalone: false
})
export class MoneyPipe implements PipeTransform {
	constructor(private cacheService: LocalStorageService) {}

	transform(
		money: MoneyDto,
		numberOfDecimals?: number,
		currencyVisibility: CurrencyVisibility = 'showCurrency',
		convertNegative: ConvertNegative = 'keepNegative'
	): string {
		if (!money || (!money.currency && currencyVisibility !== 'hideCurrency')) {
			return '';
		}
		return FormatMoney(this.cacheService, money, numberOfDecimals, currencyVisibility, convertNegative);
	}
}
export function FormatMoney(
	cacheService: LocalStorageService,
	money: MoneyDto,
	numberOfDecimals?: number,
	currencyVisibility?: CurrencyVisibility,
	convertNegative: ConvertNegative = 'keepNegative'
): string {
	const hideCurrency = currencyVisibility === 'hideCurrency';
	const convertToPositive = convertNegative === 'convertToPositive';

	if (money == null || money.amount == null) return '';

	let moneyAmount = money.amount;
	if (convertToPositive) {
		moneyAmount = Math.abs(moneyAmount);
	}

	let value = '';

	if (numberOfDecimals > 0) {
		value = moneyAmount.toFixed(numberOfDecimals);
	} else if (numberOfDecimals == 0) {
		value = Math.round(moneyAmount).toString();
	} else if (numberOfDecimals < 0) {
		value = (Math.round(moneyAmount * Math.pow(10, numberOfDecimals)) * Math.pow(10, -1 * numberOfDecimals)).toString();
	} else {
		value = moneyAmount.toString();
	}

	switch (money.currency.toUpperCase()) {
		case 'SEK':
			const currencyPrefix = cacheService.get('chosenLocale') === 'da' ? ' kr.' : ' kr';
			value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

			if (value.endsWith('.00')) {
				value = value.substr(0, value.length - 3);
			}

			return hideCurrency ? value : value + currencyPrefix;
		case 'EUR':
			value = value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

			if (value.endsWith('.00')) {
				value = value.substr(0, value.length - 3);
			}
			const negativeNumber = value.startsWith('-');
			return hideCurrency ? value : negativeNumber ? '-' + value.substring(1) + ' €' : value + ' €';

		case 'DKK':
			value = value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

			if (value.endsWith('.00')) {
				value = value.substr(0, value.length - 3);
			}

			return hideCurrency ? value : value + ' kr.';
		case 'NOK':
			value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

			if (value.endsWith('.00')) {
				value = value.substr(0, value.length - 3);
			}

			return hideCurrency ? value : value + ' kr';
		default:
			throw new Error(`Invalid currency ${money.currency}`);
	}
}
