import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface LoanFilterLoan {
	id: string;
	name: string;
}

@Component({
    selector: 'app-table-loan-filter',
    templateUrl: './table-loan-filter.component.html',
    standalone: false
})
export class TableLoanFilterComponent implements OnInit, OnChanges {
	@Input() availableLoans: LoanFilterLoan[];
	@Output() selectedLoan = new EventEmitter<string>();

	loanList: LoanFilterLoan[] = [{ id: 'all', name: this.translateService.instant('home.table-loan-filter.all') }];
	selectedLoanIndex: number;

	constructor(private translateService: TranslateService) {}

	ngOnInit() {
		this.selectedLoanIndex = 0;
		this.selectedLoan.emit(this.loanList[this.selectedLoanIndex].id);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.availableLoans) {
			if (this.availableLoans) {
				this.loanList = [...this.loanList, ...this.availableLoans];
			}
		}
	}

	handleLoanClick(index: number) {
		this.selectedLoanIndex = index;
		this.selectedLoan.emit(this.loanList[index].id);
	}
}
