import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
    standalone: false
})
export class ToastComponent implements OnInit {

  @Input() show  = false;
  @Input() type : string;
  @Input() content : string;
  @Output() close = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {}

}
