import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { fadeInOut, smoothOpenClose } from 'app/animations/smooth.animations';
import { OnboardingSessionDto } from 'app/api';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

const lightListStyle = {
	itemBgColor: 'bg-white',
	itemSelectedBgColor: 'bg-almost-black',
	itemBorderColor: 'border-almost-black',
	itemSelectedBorderColor: 'border-almost-black',
	itemTextColor: 'text-almost-black',
	itemSelectedTextColor: 'text-white',
	itemBorderRadius: 'rounded-button',
	border: 'border-2',
};

@Component({
    selector: 'app-customer-satisfaction',
    templateUrl: './customer-satisfaction.component.html',
    styleUrls: ['./customer-satisfaction.component.scss'],
    animations: [fadeInOut, smoothOpenClose],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CustomerSatisfactionComponent implements OnInit {
	@Input() sessionId: string;
	@Output() addRating: EventEmitter<number> = new EventEmitter<number>();
	@Output() addComment: EventEmitter<string> = new EventEmitter<string>();
	@Input() data: OnboardingSessionDto;
	feedbackForm: FormGroup;
	feedbackSubmitted = false;

	feedbackOptions = [
		{
			rating: 5,
			text: 'onboarding.customer-satisfaction.very-satisfied',
		},
		{
			rating: 4,
			text: 'onboarding.customer-satisfaction.satisfied',
		},
		{
			rating: 3,
			text: 'onboarding.customer-satisfaction.neutral',
		},
		{
			rating: 2,
			text: 'onboarding.customer-satisfaction.dissatisfied',
		},
		{
			rating: 1,
			text: 'onboarding.customer-satisfaction.very-dissatisfied',
		},
	];

	itemStyle = lightListStyle;

	constructor(private formBuilder: FormBuilder) {}

	get ratingCtrl(): AbstractControl {
		return this.feedbackForm.get('rating');
	}

	get commentCtrl(): AbstractControl {
		return this.feedbackForm.get('comment');
	}

	ngOnInit(): void {
		this.initializeForm();
		this.onRatingChange();
		this.checkIfFeedbackSubmitted();
	}

	checkIfFeedbackSubmitted() {
		if (localStorage.getItem('givenFeedack') === this.sessionId) {
			this.feedbackSubmitted = true;
		}
	}

	setFeedbackSubmitted() {
		localStorage.setItem('givenFeedack', this.sessionId);
		this.feedbackSubmitted = true;
	}

	initializeForm() {
		this.feedbackForm = this.formBuilder.group({
			rating: ['', { validators: [Validators.required] }],
			comment: [
				'',
				{
					validators: [Validators.minLength(5)],
					updateOn: 'blur',
				},
			],
		});
	}

	submitForm(): void {
		this.feedbackForm.markAllAsTouched();
		if (this.feedbackForm.valid) {
			this.addComment.emit(this.commentCtrl.value);
			this.setFeedbackSubmitted();
		}
	}

	selectOption(option: any): void {
		this.ratingCtrl.setValue(option);
	}

	onRatingChange() {
		this.ratingCtrl.valueChanges
			.pipe(
				distinctUntilChanged(),
				debounceTime(500),
				filter(value => !!value.rating)
			)
			.subscribe(value => {
				this.addRating.emit(value.rating);
			});
	}

	unselectOption() {
		this.ratingCtrl.setValue('');
		this.commentCtrl.setValue('');
	}
}
