import { PaymentIntervalType } from 'app/api';

export function getPaymentIntervalText(value: PaymentIntervalType): string {
	switch (value) {
		case PaymentIntervalType.Daily:
			return 'home.new-loan.payment-interval.bankday';
		case PaymentIntervalType.WeeklySimple:
			return 'home.new-loan.payment-interval.weekly';
		case PaymentIntervalType.MonthlyV2:
			return 'home.new-loan.payment-interval.monthly';
		default:
			return `home.new-loan.payment-interval.${value.toLowerCase()}`;
	}
}
