export * from './actionTracking.service';
import { ActionTrackingHttpService } from './actionTracking.service';
export * from './actionTracking.serviceInterface'
export * from './assently.service';
import { AssentlyHttpService } from './assently.service';
export * from './assently.serviceInterface'
export * from './consents.service';
import { ConsentsHttpService } from './consents.service';
export * from './consents.serviceInterface'
export * from './contracts.service';
import { ContractsHttpService } from './contracts.service';
export * from './contracts.serviceInterface'
export * from './countries.service';
import { CountriesHttpService } from './countries.service';
export * from './countries.serviceInterface'
export * from './creditProduct.service';
import { CreditProductHttpService } from './creditProduct.service';
export * from './creditProduct.serviceInterface'
export * from './credits.service';
import { CreditsHttpService } from './credits.service';
export * from './credits.serviceInterface'
export * from './customerSatisfaction.service';
import { CustomerSatisfactionHttpService } from './customerSatisfaction.service';
export * from './customerSatisfaction.serviceInterface'
export * from './index.service';
import { IndexHttpService } from './index.service';
export * from './index.serviceInterface'
export * from './kyc.service';
import { KycHttpService } from './kyc.service';
export * from './kyc.serviceInterface'
export * from './loans.service';
import { LoansHttpService } from './loans.service';
export * from './loans.serviceInterface'
export * from './logging.service';
import { LoggingHttpService } from './logging.service';
export * from './logging.serviceInterface'
export * from './onboarding.service';
import { OnboardingHttpService } from './onboarding.service';
export * from './onboarding.serviceInterface'
export * from './organizationMockData.service';
import { OrganizationMockDataHttpService } from './organizationMockData.service';
export * from './organizationMockData.serviceInterface'
export * from './organizations.service';
import { OrganizationsHttpService } from './organizations.service';
export * from './organizations.serviceInterface'
export * from './partnerBambora.service';
import { PartnerBamboraHttpService } from './partnerBambora.service';
export * from './partnerBambora.serviceInterface'
export * from './partnerClient.service';
import { PartnerClientHttpService } from './partnerClient.service';
export * from './partnerClient.serviceInterface'
export * from './partnerOnboarding.service';
import { PartnerOnboardingHttpService } from './partnerOnboarding.service';
export * from './partnerOnboarding.serviceInterface'
export * from './payments.service';
import { PaymentsHttpService } from './payments.service';
export * from './payments.serviceInterface'
export * from './pricing.service';
import { PricingHttpService } from './pricing.service';
export * from './pricing.serviceInterface'
export * from './pricingLegacy.service';
import { PricingLegacyHttpService } from './pricingLegacy.service';
export * from './pricingLegacy.serviceInterface'
export * from './purchaseFinancing.service';
import { PurchaseFinancingHttpService } from './purchaseFinancing.service';
export * from './purchaseFinancing.serviceInterface'
export * from './purposes.service';
import { PurposesHttpService } from './purposes.service';
export * from './purposes.serviceInterface'
export * from './repayment.service';
import { RepaymentHttpService } from './repayment.service';
export * from './repayment.serviceInterface'
export * from './scrapingCallbacks.service';
import { ScrapingCallbacksHttpService } from './scrapingCallbacks.service';
export * from './scrapingCallbacks.serviceInterface'
export * from './sharedFiles.service';
import { SharedFilesHttpService } from './sharedFiles.service';
export * from './sharedFiles.serviceInterface'
export * from './trapets.service';
import { TrapetsHttpService } from './trapets.service';
export * from './trapets.serviceInterface'
export const APIS = [ActionTrackingHttpService, AssentlyHttpService, ConsentsHttpService, ContractsHttpService, CountriesHttpService, CreditProductHttpService, CreditsHttpService, CustomerSatisfactionHttpService, IndexHttpService, KycHttpService, LoansHttpService, LoggingHttpService, OnboardingHttpService, OrganizationMockDataHttpService, OrganizationsHttpService, PartnerBamboraHttpService, PartnerClientHttpService, PartnerOnboardingHttpService, PaymentsHttpService, PricingHttpService, PricingLegacyHttpService, PurchaseFinancingHttpService, PurposesHttpService, RepaymentHttpService, ScrapingCallbacksHttpService, SharedFilesHttpService, TrapetsHttpService];
