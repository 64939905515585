import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FeatureFlagsService } from 'app/services/feature-flags.service';
import { MAINTENANCE_MODE } from 'app/app.contants';

@Component({
    selector: 'app-maintenance-view',
    templateUrl: './maintenance-view.component.html',
    styleUrls: ['./maintenance-view.component.scss'],
    standalone: false
})
export class MaintenanceViewComponent implements OnInit {
	constructor(private featureFlagService: FeatureFlagsService, private location: Location) {}

	ngOnInit(): void {
		if (!this.featureFlagService.isFlagEnabled(MAINTENANCE_MODE)) {
			this.location.back();
		}
	}
}
