import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-unavailable-page',
    templateUrl: './unavailable-page.component.html',
    styleUrls: ['./unavailable-page.component.scss'],
    standalone: false
})
export class UnavailablePageComponent implements OnInit {
	ngOnInit(): void {}
}
