import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryISOCode, OrganizationsHttpService, UpdateContactInfoRequest } from 'app/api';
import { PLACEHOLDER_SWEDISH_PHONE_NUMBER } from 'app/app.contants';
import { emailAsyncValidator, swedishMobilePhoneValidator } from 'app/shared/validators';

@Component({
    selector: 'save-contact-modal',
    templateUrl: './save-contact-modal.component.html',
    styleUrls: ['./save-contact-modal.component.scss'],
    standalone: false
})
export class SaveContactModalComponent implements OnInit {
	@Input() visible = false;
	@Input() closable = true;
	@Input() initialEmail: string;
	@Input() initialPhone: string;
	@Output() close = new EventEmitter<void>();
	@Output() add = new EventEmitter<UpdateContactInfoRequest>();
	contactForm: FormGroup;
	phonePlaceholder: string = PLACEHOLDER_SWEDISH_PHONE_NUMBER;

	constructor(private formBuilder: FormBuilder, private organizationHttpService: OrganizationsHttpService) {}

	get email(): AbstractControl {
		return this.contactForm.get('email');
	}

	get phone(): AbstractControl {
		return this.contactForm.get('phone');
	}

	ngOnInit() {
		this.contactForm = this.formBuilder.group({
			email: [
				this.initialEmail || '',
				{
					validators: [Validators.required],
					asyncValidators: emailAsyncValidator(this.organizationHttpService),
					updateOn: 'blur',
				},
			],
			phone: [this.initialPhone || '', { validators: swedishMobilePhoneValidator, updateOn: 'blur' }],
		});
	}

	addContact() {
		this.contactForm.markAllAsTouched();
		if (this.contactForm.valid) {
			const contact: UpdateContactInfoRequest = {
				email: this.email.value,
				phone: this.phone.value,
				countryCode: CountryISOCode.SE,
			};
			this.add.emit(contact);
		}
	}
}
