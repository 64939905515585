import { ValidatorFn, Validators } from '@angular/forms';

// Validates only German mobile phone numbers
// Format: +49 followed by mobile prefix (15x, 16x, 17x) and the rest of the number with specific spacing
// Examples: +49 151 123 45 67 (10 digits), +49 151 123 45 678 (11 digits)
// Validates only German mobile phone numbers
// Format examples (with or without the +49 prefix):
//  - +49 151 123 45 678 (11 digits)
//  - +49 151 123 45 67 (10 digits)
//  - 151 123 45 678 (11 digits)
//  - 151 123 45 67 (10 digits)
export const germanMobilePhoneValidator: ValidatorFn[] = [
	Validators.required,
	Validators.pattern(/^(\+49\s?)?[1][5-7]\d\s?\d{3}\s?\d{2}\s?\d{2,3}$/),
];
